import axios from 'axios';
import Cookies from 'js-cookie';
import { BASE_URL } from '../configs';

export const fetchOrders = (idUser, limit = 5, skip = 0) => {
  const token = Cookies.get('token'); // Lấy token từ cookies
  return axios.get(`${BASE_URL}/api/v1/user/my-order`, {
    params: {
      idOfUser: idUser,
      limit,
      skip
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
import axios from 'axios';
import Cookies from 'js-cookie';
import { BASE_URL } from '../configs';

// Hàm gọi API để kiểm tra sản phẩm
export const getInfoProductLink = async (urls, idUser) => {
    try {
        const response = await axios.post(`${BASE_URL}/api/v1/tiktokshop/get-info-product`, { urls, idUser });
        return response.data;
    } catch (error) {
        throw new Error('Failed to fetch product data');
    }
};

// Hàm gọi API để add order từ user
export const createTikTokCashbackOrder = (idOfUser, orderId) => {
    const token = Cookies.get('token'); // Lấy token từ cookies
    return axios.post(`${BASE_URL}/api/v1/tiktokshop/get-conversion/add-order`, {
        idOfUser,
        orderId,
    }, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
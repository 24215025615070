import axios from 'axios';
import { BASE_URL } from '../configs';

export const createTransactionApi = async (transactionData, token) => {
    const response = await axios.post(`${BASE_URL}/api/v1/user/transactions`, transactionData, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
};

export const getTransactionHistoryApi = async (userId, token) => {
    const response = await axios.get(`${BASE_URL}/api/v1/user/transactions/${userId}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
};
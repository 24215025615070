import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchVouchersAsync, incrementPage, resetPage, setCategory } from '../../store/vouchers/showVoucherTikTokSlice';
import formatCurrency from '../utils/formatCurrency';
import timestampToDDMM from '../utils/timestampToDDMM';

const VoucherTikTokList = () => {
  const dispatch = useDispatch();
  const vouchers = useSelector((state) => state.voucherTikTok.vouchers);
  const page = useSelector((state) => state.voucherTikTok.page);
  const totalPages = useSelector((state) => state.voucherTikTok.totalPages);
  const status = useSelector((state) => state.voucherTikTok.status);
  const category = useSelector((state) => state.voucherTikTok.category);
  const [copiedVoucherId, setCopiedVoucherId] = useState(null);

  useEffect(() => {
    dispatch(resetPage());
    dispatch(fetchVouchersAsync({ page: 1, category }));
  }, [dispatch, category]);

  const handleCopy = (id, code) => {
    navigator.clipboard.writeText(code);
    setCopiedVoucherId(id);
  };

  const handleBuyNow = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };  

  const handleLoadMore = () => {
    if (page < totalPages && status === 'succeeded') {
      dispatch(incrementPage());
      dispatch(fetchVouchersAsync({ page: page + 1, category }));
    }
  };

  const handleFilterChange = (category) => {
    dispatch(setCategory(category));
  };

  const filteredVouchers = category === 'Tất cả'
    ? vouchers
    : vouchers.filter(voucher => voucher.promotionCat === category);

  return (
    <div>
      <div className="overflow-x-auto whitespace-nowrap mb-2 py-2">
        <button
          className={`px-4 py-2 m-1 rounded ${category === 'Tất cả' ? 'bg-green-100 text-green-700' : 'bg-gray-100 text-gray-700'}`}
          onClick={() => handleFilterChange('Tất cả')}
        >
          Tất cả
        </button>
        {['Toàn sàn', 'Freeship'].map(categoryItem => (
          <button
            key={categoryItem}
            className={`px-4 py-2 m-1 rounded ${category === categoryItem ? 'bg-green-100 text-green-700' : 'bg-gray-100 text-gray-700'}`}
            onClick={() => handleFilterChange(categoryItem)}
          >
            {categoryItem}
          </button>
        ))}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {filteredVouchers.map((voucher) => (
          <div key={voucher._id} className="p-1 border rounded-md shadow-md flex flex-col bg-white">
            <div className="flex">
              {/* Cột trái */}
              <div className="w-1/4 flex flex-col items-center justify-center">
                <img src={voucher.cover} alt="Voucher cover" className="w-11 h-auto rounded-xl mb-1 bg-[#fe2453]" />
                <p className="text-[10px]">{voucher.promotionCat}</p>
                <p className="text-[10px]">
                  {voucher.startTime && voucher.startTime > Date.now() ? `Hiệu lực: ${timestampToDDMM(voucher.startTime / 1000)}` : `HSD: ${timestampToDDMM(voucher.endTime / 1000)}`}
                </p>
              </div>

              {/* Cột giữa */}
              <div className="w-full px-2 flex flex-col justify-between">
                <p className="text-md font-bold">
                  {voucher.promotionType === 'percent' ? `Giảm ${voucher.discountAmount}% tối đa ${formatCurrency(voucher.maxDiscount)}` : `Giảm ${formatCurrency(voucher.discountAmount)}`}
                </p>
                <div className="flex justify-between">
                  <div className="items-start">
                    <p className="text-sm text-gray-500">Đơn tối thiểu: {formatCurrency(voucher.minOrderAmount)}</p>
                    <p className="text-sm text-gray-500">
                      Lượt dùng còn: {voucher.percentUsed ? `${voucher.percentUsed}%` : '100%'}
                    </p>
                    {voucher.promotionCode ? (
                      <p className="text-xs text-green-700 underline cursor-pointer" onClick={() => handleBuyNow('nhap-ma')}>#Cách nhập mã TikTok</p>
                    ) : (
                      <p className="text-xs text-green-700 underline cursor-pointer" onClick={() => handleBuyNow('huong-dan')}>#Cách lưu mã TikTok</p>
                    )}
                  </div>
                  <div className="text-sm flex items-center justify-end">
                    {voucher.promotionCode ? (
                      <button
                        className="text-white font-medium px-4 py-1 rounded bg-[#fe2453] getvoucher"
                        onClick={() => handleCopy(voucher._id, voucher.promotionCode)}
                      >
                        {copiedVoucherId === voucher._id ? 'Đã copy!' : 'Copy mã'}
                      </button>
                    ) : (
                      <button
                        className="text-white font-medium px-4 py-1 rounded bg-[#fe2453] getvoucher"
                        onClick={() => handleBuyNow('huong-dan')}
                      >
                        Lưu mã
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {status === 'loading' && <p>Loading...</p>}
      {page < totalPages && (
        <div className="flex justify-center my-4">
          <button
            className="bg-green-100 font-medium text-green-700 px-4 py-2 rounded"
            onClick={handleLoadMore}
          >
            Xem thêm voucher
          </button>
        </div>
      )}
    </div>
  );
};

export default VoucherTikTokList;
import React, { useState, useEffect } from 'react';
import { fetchActivePopups } from '../../api/other/popupImageApi';

const PopupImage = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [popupData, setPopupData] = useState(null);
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    const lastClosePopupTime = localStorage.getItem('lastClosePopupTime');
    if (lastClosePopupTime) {
      const timeElapsed = Date.now() - parseInt(lastClosePopupTime, 10);
      if (timeElapsed < 12 * 60 * 60 * 1000) {
        return;
      }
    }

    const fetchPopup = async () => {
      try {
        const data = await fetchActivePopups();
        if (data && data.length > 0) {
          setPopupData(data[0]); // Giả sử hiển thị popup đầu tiên từ danh sách
          const timer = setTimeout(() => {
            setIsAnimating(true);
            setIsVisible(true);
          }, 4000);
          return () => clearTimeout(timer);
        }
      } catch (error) {
        console.error('Lỗi khi lấy popup:', error);
      }
    };

    fetchPopup();
  }, []);

  useEffect(() => {
    let countdownTimer;
    if (isVisible) {
      countdownTimer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown === 1) {
            closePopup();
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);
    }
    return () => clearInterval(countdownTimer);
  }, [isVisible]);

  const closePopup = () => {
    setIsAnimating(false);
    setTimeout(() => {
      setIsVisible(false);
      localStorage.setItem('lastClosePopupTime', Date.now().toString());
    }, 300);
  };

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      closePopup();
    }
  };

  return (
    <>
      {isVisible && popupData && (
        <div
          className={`fixed inset-0 bg-gray-900 bg-opacity-75 flex justify-center items-center z-50 transition-opacity duration-300 ${
            isAnimating ? 'opacity-100' : 'opacity-0'
          }`}
          onClick={handleBackgroundClick}
        >
          <div
            className={`p-4 rounded-lg relative transform transition-all duration-500 ${
              isAnimating ? 'scale-100 opacity-100' : 'scale-90 opacity-0'
            }`}
          >
            <div className="relative">
              <a href={popupData.link} rel="noopener noreferrer" target="_blank">
                <img
                  src={popupData.imageUrl}
                  alt="Banner"
                  className="mx-auto w-4/5 sm:w-full h-auto rounded-lg shadow-xl"
                />
              </a>
              <div className="text-center mt-1">
                <p className="text-xs sm:text-sm text-gray-200">Đóng sau {countdown} giây</p>
                <button
                  onClick={closePopup}
                  className="mt-1 text-white px-2 border border-gray-500 rounded-full transition duration-200"
                >
                  x
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PopupImage;
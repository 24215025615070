import axios from 'axios';
import { BASE_URL } from '../configs';

export const verifyEmail = async (data) => {
  try {
    const response = await axios.post(`${BASE_URL}/verify-email`, data);
    return response;
  } catch (error) {
    throw error;
  }
};
import axios from 'axios';
import Cookies from 'js-cookie';
import { BASE_URL } from '../configs';

export const markNotificationAsRead = (notificationId) => {
    const token = Cookies.get('token');
  return axios.put(`${BASE_URL}/api/v1/user/notifications/${notificationId}`, {}, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getProductInfo } from '../../api/deals/productInfoApi';
import { useParams } from 'react-router-dom';
import { FaArrowRight } from "react-icons/fa6";
import formatCurrency from '../utils/formatCurrency';
import SEOMeta from '../SEOMeta';

const ProductInfo = () => {
  const { dealSlug } = useParams();
  const [productInfo, setProductInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false); // Thêm state để xử lý lỗi không tìm thấy sản phẩm

  const user = useSelector((state) => state.profile.user);
  const idUser = user?.idUser || 'notlogin';
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProductInfo = async () => {
      try {
        const data = await getProductInfo(dealSlug);
        if (data.length === 0) {
          setError(true);
        } else {
          setProductInfo(data);
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching product info:', error);
        setError(true);
      }
    };
    fetchProductInfo();
  }, [dealSlug]);

  const handleClickBuyNow = () => {
    if (idUser === 'notlogin') {
      navigate('/login');
    } else {
      const urlProduct = `https://s.shopee.vn/an_redir?origin_link=${encodeURIComponent(productInfo[0].productLink)}&affiliate_id=17358790110&sub_id=tui3gang-${idUser}`;
      window.open(`/redirect?url=${encodeURIComponent(urlProduct)}`);
    }
  };

  // Nếu không tìm thấy sản phẩm, hiển thị trang 404 tùy chỉnh
  if (error) {
    return (
      <div>
        <SEOMeta title="Không tìm thấy sản phẩm" description="Sản phẩm bạn đang tìm kiếm không tồn tại." />
        <div className="container mx-auto py-4 text-center">
          <h3 className="text-2xl font-bold mb-4">Không tìm thấy sản phẩm này!</h3>
          <p className="text-gray-600 mb-1">Sản phẩm bạn đang tìm không tồn tại hoặc đã bị gỡ bỏ.</p>
          <p className="text-center text-gray-600">
              Bạn hãy thử {' '}
              <span className="text-orange-600">
                tải lại trang
              </span>{' '}
              hoặc{' '}
              <span className="text-orange-600">
                quay về trang chủ.
              </span>
          </p>
          <button 
            onClick={() => navigate('/')}
            className="mt-4 px-4 py-2 bg-green-700 text-white rounded hover:bg-green-600"
          >
            Về trang chủ
          </button>
        </div>
      </div>
    );
  }

  if (loading) {
    return <p>Đang tải thông tin sản phẩm...</p>;
  }

  return (
    <div>
      <SEOMeta 
        title={`${productInfo[0]?.productName} hoàn tiền đến ${formatCurrency(Number(productInfo[0]?.commission))}`}
        description={`Mua ${productInfo[0]?.productName} thông qua Tui3Gang.Com tiền hoàn đến ${formatCurrency(Number(productInfo[0]?.commission))}.`}
      />
      <div className="container mx-auto py-4">
        <ul>
          {productInfo.map((product, index) => (
            <li key={index} className="bg-white shadow-md rounded-lg overflow-hidden mb-4">
              <img
                src={product.imageUrl}
                alt={product.productName}
                className="w-full sm:w-1/2 h-auto mx-auto rounded-t-lg object-cover"
              />
              <div className="p-4">
                <h3 className="text-lg font-semibold mb-2">{product.productName}</h3>
                <p>Giá bán: <span className='px-1 py-[2px] rounded-lg text-fuchsia-600 bg-fuchsia-50'>{formatCurrency(Number(product.priceMin))}</span></p>
                <p className="mb-3">Hoàn tiền đến: <span className='text-lg px-2 py-[2px] rounded-lg text-green-700 bg-green-100'>{formatCurrency(Number(product.commission))}</span></p>
                <p className="text-gray-600 italic mt-4 pt-4 border-t border-dashed border-gray-300"><b>Tui3Gang.Com</b> hợp tác cùng <b><a href={`/shop/${product.shopNameSlug}`}>{product.shopName}</a></b> và <b>{product.merchantName}</b> mang đến chương trình hoàn tiền cho bạn khi mua sản phẩm này trên {product.merchantName} theo các bước dưới đây👇</p>

                {/* Timeline */}
                <div className="relative pt-6">
                  {/* Đường timeline nét đứt bắt đầu từ điểm 1 */}
                  <div className="absolute top-6 left-3 h-full w-px border-l border-dashed border-gray-400"></div>

                  {/* Điểm 1 */}
                  <div className="relative flex items-start mb-4">
                    <div className="flex-shrink-0 w-6 h-6 bg-green-600 text-white rounded-full flex items-center justify-center">1</div>
                    <div className="ml-3">
                      <h4 className="font-medium">Ấn nút mua deal</h4>
                      <button
                        className="mt-2 px-4 py-1 bg-green-700 text-white rounded hover:bg-white border border-green-700 hover:text-green-700 focus:outline-none"
                        onClick={handleClickBuyNow}
                      >
                        Mua Deal <FaArrowRight className="inline-block" />
                      </button>
                    </div>
                  </div>

                  {/* Điểm 2 */}
                  <div className="relative flex items-start mb-4">
                    <div className="flex-shrink-0 w-6 h-6 bg-green-600 text-white rounded-full flex items-center justify-center">2</div>
                    <div className="ml-3">
                      <h4 className="font-medium">Mua hàng trên {product.merchantName}</h4>
                      <p className="text-gray-600">Tiến hành đặt hàng trên app {product.merchantName} như bình thường (có thể dùng mã giảm giá, freeship).</p>
                    </div>
                  </div>

                  {/* Điểm 3 */}
                  <div className="relative flex items-start mb-4">
                    <div className="flex-shrink-0 w-6 h-6 bg-green-600 text-white rounded-full flex items-center justify-center">3</div>
                    <div className="ml-3">
                      <h4 className="font-medium">Xem đơn hoàn tiền</h4>
                      <p className="text-gray-600">Sau 24H, vào mục <a href='/my-order' className='text-blue-600 hover:underline'>Lịch sử hoàn tiền</a> để xem đơn hoàn tiền của bạn.</p>
                    </div>
                  </div>

                  {/* Điểm 4 */}
                  <div className="relative flex items-start">
                    <div className="flex-shrink-0 w-6 h-6 bg-green-600 text-white rounded-full flex items-center justify-center">4</div>
                    <div className="ml-3">
                      <h4 className="font-medium">Nhận tiền hoàn về</h4>
                      <p className="text-gray-600">Khoảng 5 - 7 ngày sau khi nhận hàng, vào mục <a href='/withdraw' className='text-blue-600 hover:underline'>Rút tiền</a> để rút tiền về ngân hàng hoặc đổi lấy voucher mua sắm.</p>
                    </div>
                  </div>
                </div>

                <p className='mt-8 italic'><span className='font-medium'>Lưu ý</span>: Sau khi ấn nút "Mua Deal", bạn KHÔNG được ấn vào bất cứ link nào trên web, app hoặc Facebook, Zalo, Telegram,... khác để tránh việc hoàn tiền không được ghi nhận. Xem chi tiết <a className='text-blue-700 hover:underline' href='/bai-viet/chinh-sach-hoan-tien-shopee'>chính sách hoàn tiền ở đây</a>.</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ProductInfo;
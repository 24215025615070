import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { verifyEmail } from '../../api/user/verifyEmail';

export const verifyEmailThunk = createAsyncThunk(
  'verifyEmail',
  async (data, thunkAPI) => {
    try {
      const response = await verifyEmail(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const verifyEmailSlice = createSlice({
  name: 'verifyEmail',
  initialState: {
    isLoading: false,
    error: null,
    success: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(verifyEmailThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(verifyEmailThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isVerified = true;
      })
      .addCase(verifyEmailThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default verifyEmailSlice.reducer;
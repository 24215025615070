import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchOrders } from '../../api/user/showMyOrder';
import { createTransactionApi, getTransactionHistoryApi } from '../../api/user/transactions';

// Thunk để lấy đơn hàng của người dùng
export const fetchOrdersAsync = createAsyncThunk(
  'userTransaction/fetchOrders',
  async ({ idUser, limit, skip }) => {
    const response = await fetchOrders(idUser, limit, skip);
    return response.data;
  }
);

// Thunk để lấy lịch sử giao dịch của người dùng
export const fetchTransactionHistoryAsync = createAsyncThunk(
  'userTransaction/fetchTransactionHistory',
  async ({ userId, token }, { rejectWithValue }) => {
    try {
      const response = await getTransactionHistoryApi(userId, token);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk để tạo giao dịch mới (rút tiền)
export const createTransaction = createAsyncThunk(
  'userTransaction/createTransaction',
  async ({ transactionData, token }, { rejectWithValue }) => {
    try {
      const response = await createTransactionApi(transactionData, token);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const userTransactionSlice = createSlice({
  name: 'userTransaction',
  initialState: {
    orders: [],
    transaction: null,
    transactionHistory: [],
    status: 'idle',
    error: null,
    totalWithdrawableAmount: 0,
    totalPendingAmount: 0,
    totalWithdrawnAmount: 0,
    loading: false,
  },
  reducers: {
    resetOrders: (state) => {
      state.orders = [];
      state.status = 'idle';
      state.error = null;
      state.totalWithdrawableAmount = 0;
      state.totalPendingAmount = 0;
      state.totalWithdrawnAmount = 0;
      state.transactionHistory = [];
    }
  },
  extraReducers: (builder) => {
    builder
      // Xử lý fetchOrdersAsync
      .addCase(fetchOrdersAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOrdersAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.orders = [...state.orders, ...action.payload];
      })
      .addCase(fetchOrdersAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      // Xử lý fetchTransactionHistoryAsync
      .addCase(fetchTransactionHistoryAsync.fulfilled, (state, action) => {
        state.transactionHistory = action.payload;
        state.totalWithdrawnAmount = action.payload.reduce((acc, transaction) => {
          return acc + transaction.withdrawCredit;
        }, 0);
      })
      .addCase(fetchTransactionHistoryAsync.rejected, (state, action) => {
        state.error = action.payload;
      })

      // Xử lý createTransaction
      .addCase(createTransaction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createTransaction.fulfilled, (state, action) => {
        state.loading = false;
        state.transaction = action.payload;
      })
      .addCase(createTransaction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetOrders } = userTransactionSlice.actions;

export default userTransactionSlice.reducer;
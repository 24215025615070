import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie'; // Import thư viện js-cookie
import { changePassword } from '../../api/user/changePassword';

export const changePasswordThunk = createAsyncThunk(
  'changePassword',
  async ({ currentPassword, newPassword }, thunkAPI) => {
    try {
      const token = Cookies.get('token'); // Lấy token từ cookies
      const response = await changePassword({ currentPassword, newPassword }, token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const changePasswordSlice = createSlice({
  name: 'changePassword',
  initialState: {
    isLoading: false,
    error: null,
    success: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(changePasswordThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(changePasswordThunk.fulfilled, (state) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(changePasswordThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default changePasswordSlice.reducer;
import React from 'react';
import { FaZ, FaTelegram, FaFacebook } from "react-icons/fa6";
import { GROUP_ZALO_URL, GROUP_TELEGRAM_URL, GROUP_FB_URL } from '../../api/configs';

const BoxJoinGroup = () => {

    const handleClick = (url) => {
        window.open(url, '_blank');
    };

    return (
        <div>
            <h3 className="p-2 font-bold text-center">Tham gia group để nhận voucher nhanh hơn!</h3>
            <div className="grid grid-cols-3 h-36 gap-2 pb-4 relative text-sm text-center"> {/* Change to grid-cols-3 */}
                <div className="flex flex-col w-full justify-center items-center bg-white h-30 rounded-md shadow-md drop-shadow-md border cursor-pointer" onClick={() => handleClick(GROUP_ZALO_URL)}>
                    <span className="relative flex h-3 w-3 left-14">
                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                        <span className="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
                    </span>
                    <span className='font-medium'>Cập nhật mã giảm giá</span>
                    <FaZ className="text-sky-500 text-4xl" />
                    <span className="text-base">trên Zalo</span>
                </div>
                <div className="flex flex-col w-full justify-center items-center bg-white h-30 rounded-md shadow-md drop-shadow-md border cursor-pointer" onClick={() => handleClick(GROUP_TELEGRAM_URL)}>
                    <span className="relative flex h-3 w-3 left-14">
                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                        <span className="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
                    </span>
                    <span className='font-medium'>Cập nhật mã giảm giá</span>
                    <FaTelegram className="text-sky-400 text-4xl" />
                    <span className="text-base">trên Telegram</span>
                </div>
                <div className="flex flex-col w-full justify-center items-center bg-white h-30 rounded-md shadow-md drop-shadow-md border cursor-pointer" onClick={() => handleClick(GROUP_FB_URL)}> 
                    <span className="relative flex h-3 w-3 left-14">
                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                        <span className="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
                    </span>
                    <span className='font-medium'>Cập nhật thông tin</span>
                    <FaFacebook className="text-blue-600 text-4xl" />
                    <span className="text-base">trên Facebook</span>
                </div>
            </div>
        </div>
    );
};

export default BoxJoinGroup;
import axios from 'axios';
import { BASE_URL } from '../configs';

export const getProductInfo = async (dealSlug) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/v1/deal/${dealSlug}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching product info by itemId:', error);
    throw error;
  }
};
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGoogleOneTapLogin } from '@react-oauth/google';
import { googleLogin } from '../../api/user/authGoogleApi';
import { getUserThunk } from '../../store/user/profileSlice';
import Cookies from 'js-cookie';

const GoogleOneTapLogin = () => {
  const isLoggedIn = useSelector((state) => state.profile.isLoggedIn);
  const [shouldUseOneTap, setShouldUseOneTap] = useState(false);

  useEffect(() => {
    const token = Cookies.get('token');
    if (!token && !isLoggedIn) {
      setShouldUseOneTap(true);
    }
  }, [isLoggedIn]);

  return shouldUseOneTap ? <GoogleOneTap /> : null;
};

const GoogleOneTap = () => {
  const dispatch = useDispatch();

  useGoogleOneTapLogin({
    onSuccess: async (response) => {
      const { credential } = response;
      const ref = Cookies.get('ref');

      try {
        const data = await googleLogin({ credential, ref });

        // Lưu token và user vào cookies
        Cookies.set('token', data.token, { expires: 365 }); // token hết hạn sau 365 ngày
        Cookies.set('user', JSON.stringify(data.user), { expires: 365 });

        // Dispatch getUserThunk để cập nhật trạng thái người dùng trong Redux
        await dispatch(getUserThunk());

        // Điều hướng quay về trang trước sau khi đăng nhập
        if (window.history.length > 1) {
          window.history.go(1);
        } else {
          window.location.href = '/';
        }
      } catch (error) {
        console.error('Google login failed', error);
      }
    },
    onError: (error) => {
      console.error('Google login error', error);
    },
    enabled: true,
  });

  return null;
};

export default GoogleOneTapLogin;
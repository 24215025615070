import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchProductInfo, resetProductInfo } from '../../store/shopcashbacks/getInfoProductShopeeSlice';
import { FaClipboard, FaCartShopping, FaShareNodes } from 'react-icons/fa6';
import formatCurrency from '../utils/formatCurrency';

const FormShopeeCashback = () => {
    const dispatch = useDispatch();
    const [url, setUrl] = useState('');
    const user = useSelector((state) => state.profile.user);
    const idUser = user?.idUser || 'notlogin';
    const navigate = useNavigate(); // Sử dụng useHistory để chuyển hướng

    const inputRef = useRef(null);

    const loading = useSelector((state) => state.productShopee.loading);
    const productInfo = useSelector((state) => state.productShopee.productInfo);
    const error = useSelector((state) => state.productShopee.error);

    const handleFetchProductInfo = useCallback(() => {
        dispatch(fetchProductInfo({ url, idUser }));
    }, [dispatch, url, idUser]);

    useEffect(() => {
        if (url.trim() !== '') {
            handleFetchProductInfo();
        }
    }, [url, handleFetchProductInfo]);

    useEffect(() => {
        return () => {
            dispatch(resetProductInfo());
        };
    }, [dispatch]);

    const handlePasteFromClipboard = () => {
        navigator.clipboard.readText().then((clipboardText) => {
            setUrl(clipboardText.trim());
        }).catch((error) => {
            console.error('Failed to read clipboard text:', error);
        });
    };

    const handleInputChange = (e) => {
        setUrl(e.target.value);
    };

    const handleInputFocus = () => {
        setUrl('');
    };

    const handleCustomPaste = (e) => {
        e.preventDefault();
        navigator.clipboard.readText().then((clipboardText) => {
            if (url.trim() === '') {
                setUrl(clipboardText.trim());
            }
        }).catch((error) => {
            console.error('Failed to read clipboard text:', error);
        });
    };

    const handleClickBuyNow = () => {
        if (idUser === 'notlogin') {
            navigate('/login'); // Chuyển hướng tới trang /login nếu chưa đăng nhập
        } else {
            const urlProduct = `https://s.tui3gang.com${productInfo[0].shortUrl}`;
            window.open(`/redirect?url=${urlProduct}`);
        }
    };

    const handleShare = () => {
        if (navigator.share) {
            navigator.share({
                title: 'Sản phẩm này đang có giá tốt quá nè!',
                text: 'Bạn ơi! Chốt đơn sản phẩm này đi, giá đang tốt quá nè',
                url: `https://s.tui3gang.com${productInfo[0].shortUrl}`,
            }).then(() => {
                console.log('Successful share');
            }).catch((error) => {
                console.log('Error sharing:', error);
            });
        } else {
            console.log('Web Share API is not supported in this browser.');
        }
    };

    const truncateText = (text, length) => {
        if (text.length <= length) return text;
        return text.substring(0, length) + '...';
      };
    
    return (
        <div className='mb-4'>
                <div className="flex items-center border border-gray-200 rounded-md px-3 py-1">
                    <input
                        type="text"
                        className="text-sm sm:text-base w-full bg-gray-50 focus:outline-none"
                        placeholder="Nhập link sản phẩm Shopee cần mua"
                        value={url}
                        onChange={handleInputChange}
                        ref={inputRef}
                        onFocus={handleInputFocus}
                        onPaste={handleCustomPaste}
                    />
                    <button
                        className="ml-2 focus:outline-none flex items-center border border-gray-200 p-2 rounded-lg"
                        onClick={handlePasteFromClipboard}
                    >
                        <FaClipboard className="text-gray-500 hover:text-gray-600 cursor-pointer pr-1" /> Dán
                    </button>
                </div>

                {loading && <p className="mt-4 text-center text-gray-600">Đang tải thông tin hoàn tiền...</p>}
                {error && <p className="mt-4 p-1 rounded-md text-center text-red-600 bg-red-50">Hãy nhập đúng link sản phẩm để được hoàn tiền!</p>}
                {productInfo && Array.isArray(productInfo) && productInfo.length > 0 && (
                    <div className="mt-4">
                        <p className="font-bold text-lg py-3">Thông tin hoàn tiền</p>
                        {productInfo.map((product) => (
                            <div key={product.itemId} className="mb-2 p-2 bg-gray-50 rounded-md">
                                <div className="flex items-center mb-3">
                                    <img
                                        src={product.imageUrl}
                                        alt={product.productName}
                                        className="w-24 h-24 mr-4 rounded-md"
                                    />
                                    <div>
                                        <h2 className="text-md font-medium">{truncateText(product.productName, 50)}</h2>
                                        <p>Giá bán: <span className="px-1 py-[2px] rounded-lg text-fuchsia-600 bg-fuchsia-50">{formatCurrency(Number(product.priceMin))}</span></p>
                                        <p>Hoàn tiền đến: <span className="text-lg px-2 py-[2px] rounded-lg text-green-700 bg-green-100">{formatCurrency(product.commission)}</span></p>
                                    </div>
                                </div>
                                <div className="flex justify-center">
                                    <button
                                        className="px-8 py-1 bg-green-700 text-white rounded-md hover:bg-green-600 focus:outline-none muangay"
                                        onClick={handleClickBuyNow} // Sử dụng handleBuyNow để kiểm tra đăng nhập và mở trang Mua ngay
                                    >
                                        <FaCartShopping className="inline-block mr-2" /> Mua ngay
                                    </button>
                                    <button
                                        className="ml-4 px-4 py-1 text-green-700 rounded-md border border-green-700 hover:bg-green-200 focus:outline-none"
                                        onClick={handleShare}
                                    >
                                        <FaShareNodes className="inline-block mr-2" /> Chia sẻ
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
        </div>
    );
};

export default FormShopeeCashback;
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchVouchersTikTok } from '../../api/vouchers/voucherTikTokApi';

export const fetchVouchersAsync = createAsyncThunk(
  'voucherTikTok/fetchVouchersTikTok',
  async ({ page, category }, { rejectWithValue }) => {
    try {
      const response = await fetchVouchersTikTok(page, category);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const voucherTikTokSlice = createSlice({
  name: 'voucherTikTok',
  initialState: {
    vouchers: [],
    page: 1,
    totalPages: 0,
    totalVouchers: 0,
    status: 'idle',
    error: null,
    category: 'Tất cả',
  },
  reducers: {
    incrementPage(state) {
      state.page += 1;
    },
    resetPage(state) {
      state.page = 1;
      state.vouchers = [];
      state.totalPages = 0;
      state.totalVouchers = 0;
      state.status = 'idle';
      state.error = null;
    },
    setCategory(state, action) {
      state.category = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVouchersAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchVouchersAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.vouchers = [...state.vouchers, ...action.payload.vouchers];
        state.totalPages = action.payload.totalPages;
        state.totalVouchers = action.payload.totalVouchers;
      })
      .addCase(fetchVouchersAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload ? action.payload.message : action.error.message;
      });
  },
});

export const { incrementPage, resetPage, setCategory } = voucherTikTokSlice.actions;

export default voucherTikTokSlice.reducer;
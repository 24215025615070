import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store';
import TagManager from 'react-gtm-module';
import { GoogleOAuthProvider } from '@react-oauth/google';

import './index.css';
import App from './App';
import GoogleOneTapLogin from './components/user/GoogleOneTapLogin';

// Khai báo Google Tag Manager
const tagManagerArgs = {
  gtmId: 'GTM-M5N95426'
};
TagManager.initialize(tagManagerArgs);
window.dataLayer = window.dataLayer || [];
window.dataLayer.push({
  event: 'pageview',
  page_path: window.location.pathname
});

const clientId = "751131332917-e89u3uvoqots3voq4jj43nd9n4rdi5ov.apps.googleusercontent.com";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GoogleOAuthProvider clientId={clientId}>
    <Provider store={store}>
      <GoogleOneTapLogin />
      <App />
    </Provider>
  </GoogleOAuthProvider>
);
import React from 'react';
import { FaHouse, FaTags, FaMoneyBill1, FaSackDollar, FaUser } from 'react-icons/fa6';
import { Link } from 'react-router-dom';

const StickyFooterBar = () => {
  return (
    <div className="fixed bottom-0 left-0 w-full bg-white border-t shadow-lg transition-transform duration-300">
      <div className="flex justify-around py-2 max-w-[768px] items-center mx-auto text-green-700">
        <Link to="/">
          <div className="flex flex-col items-center">
            <FaHouse size={22} />
            <span className="text-sm">Trang chủ</span>
          </div>
        </Link>
        <Link to="/vouchers">
          <div className="flex flex-col items-center">
            <FaTags size={22} />
            <span className="text-sm">Mã giảm giá</span>
          </div>
        </Link>
        <Link to="/hoan-tien">
          <div className="flex flex-col items-center">
            <FaMoneyBill1 size={22} />
            <span className="text-sm">Hoàn tiền</span>
          </div>
        </Link>
        <Link to="/withdraw">
          <div className="flex flex-col items-center">
            <FaSackDollar size={22} />
            <span className="text-sm">Rút tiền</span>
          </div>
        </Link>
        <Link to="/profile">
          <div className="flex flex-col items-center">
            <FaUser size={22} />
            <span className="text-sm">Cá nhân</span>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default StickyFooterBar;
import axios from 'axios';
import { BASE_URL } from './configs';

export const findProductInVideo = async (nameProduct) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/v1/shopee/find-product-in-video`, { nameProduct });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
import React from 'react';
import SEOMeta from '../SEOMeta';
import {formattedMonthYear, formattedDate} from '../utils/formatTimeNow';
import BoxJoinGroup from '../other/BoxJoinGroup';
import FormCheckCashbackTikTokShop from '../shopcashbacks/FormTikTokShopCashback';
import VoucherTikTokList from './ListVoucherTikTok';
import { Link } from 'react-router-dom';

const VoucherTikTok = () => {

  return (
    <>
        <SEOMeta 
          title={`Mã giảm giá TikTok Shop + mã freeship mới nhất tháng ${formattedMonthYear}`}
          description={`Mã giảm giá TikTok Shop mới nhất tháng ${formattedMonthYear} còn dùng được 100%. Cách lấy mã TikTok Shop áp toàn sàn và mã freeship TikTok Shop mọi đơn hàng.`}
        />
        <p className="my-3">Bạn mua hàng trên TikTok Shop và muốn lấy <b>mã giảm giá TikTok Shop</b> để được giảm nhiều hơn! Vậy thì bài viết này sẽ giúp bạn lấy các voucher TikTok Shop mới nhất và cả mã freeship TikTok nữa đó.</p>
        <p className="my-3">Ngoài ra, bạn mua hàng TikTok nhớ dán link vào <b>Tui3Gang.Com</b> để nhận tiền hoàn nhé. Với Túi 3 Gang, bạn không chỉ lấy được mã giảm giá mà còn được nhận tiền hoàn mỗi khi mua hàng đó.</p>
        <p className="text-2xl font-semibold my-5 p-2">Mã giảm giá TikTok Shop ngày {formattedDate}</p>
        <FormCheckCashbackTikTokShop />
        <VoucherTikTokList />
        <p className="text-2xl font-semibold my-5 p-2" id='huong-dan'>Cách lấy mã giảm giá TikTok Shop mới nhất</p>
        <p className='my-3'>Để lưu các mã TikTok mà Tui3Gang chia sẻ, bạn làm theo các bước sau đây:</p>
        <p className='my-3'><b>Bước 1:</b> Mở ứng dụng TikTok và chọn tab <b>"Cửa hàng"</b> (Shop) ở menu bên dưới, sau đó chọn mục <b>Freeship</b> ở phía trên.</p>
        <img className='max-w-72 md:max-w-80 mx-auto block my-5' src='/images/guides/cach-lay-ma-giam-gia-tiktok-shop-1.png' alt='Cách lấy mã giảm giá TikTok Shop 1' />
        <p className='my-3'><b>Bước 2:</b> Tại đây, bạn sẽ thấy rất nhiều mã freeship và mã giảm giá TikTok mới nhất. Hãy ấn vào nút <b>"Nhận"</b> để lưu mã vào tài khoản TikTok của bạn.</p>
        <img className='max-w-72 md:max-w-80 mx-auto block my-5' src='/images/guides/cach-lay-ma-giam-gia-tiktok-shop-2.png' alt='Cách lấy mã giảm giá TikTok Shop 2' />
        <p className='my-3'><b>Bước 3:</b> Bạn có thể ấn vào mục <b>"Voucher của bạn"</b>, để xem các mã voucher TikTok mà bạn đã lưu. Muốn sử dụng voucher nào bạn có thể ấn vào nút <b>"Sử dụng"</b>.</p>
        <p className='my-3'><b>Bổ sung thêm:</b> Ngoài cách lấy ở trên thì trước hoặc trong những ngày sale, TikTok thường tung ra các voucher lưu sớm hoặc voucher chớp nhoáng ở khu vực banner phía trên đầu hoặc icon popup ở ngay trang chủ của cửa hàng TikTok. Giống như hình dưới.</p>
        <img className='max-w-72 md:max-w-80 mx-auto block my-5' src='/images/guides/cach-lay-ma-giam-gia-tiktok-shop-4.png' alt='Cách lấy mã giảm giá TikTok Shop 4' />
        <p className="text-2xl font-semibold my-5 p-2" id='nhap-ma'>Hướng dẫn cách nhập mã TikTok Shop</p>
        <p className='my-3'>Ngoài cách lưu mã TikTok ở trên, đôi khi TikTok cũng tung mã voucher TikTok nhập tay mà bạn chỉ cần gõ vào ô tìm kiếm là có thể thu thập được mã TikTok.</p>
        <p className='my-3'><b>Cách làm đơn giản như sau:</b></p>
        <p className='my-3 ml-3'>1. Bạn copy mã mà Tui3Gang chia sẻ ở trên. Sau đó mở TikTok lên và dán mã vào ô tìm kiếm của TikTok rồi ấn "Tìm kiếm".</p>
        <p className='my-3 ml-3'>2. Một popup chứa mã giảm giá hiện lên, bạn ấn "Nhận" để lưu mã lại.</p>
        <img className='max-w-72 md:max-w-80 mx-auto block my-5' src='/images/guides/cach-nhap-ma-giam-gia-tiktok-shop.png' alt='Cách nhập mã giảm giá TikTok Shop' />
        <p className='my-3'>Bạn hãy tham gia các group của Tui3Gang.Com để không bỏ lỡ thông tin mới nhất.</p>
        <BoxJoinGroup />
        <p className="text-2xl font-semibold my-5 p-2">Hướng dẫn cách mua hàng nhận tiền hoàn từ TikTok Shop</p>
        <p className='my-3'><b>Bước 1:</b> Mở sản phẩm bạn cần mua trên TikTok Shop rồi chọn icon <b>"Chia sẻ"</b> ở phía trên. Sau đó, chọn <b>"Sao chép liên kết"</b> để copy link sản phẩm.</p>
        <img className='max-w-72 md:max-w-80 mx-auto block my-5' src='/images/guides/cach-nhan-tien-hoan-tiktok-shop-1.png' alt='Cách nhận tiền hoàn TikTok Shop 1' />
        <p className='my-3'><b>Bước 2:</b> Truy cập Tui3Gang.Com và chọn hoàn tiền TikTok Shop. Sau đó, dán link sản phẩm để kiểm tra hoàn tiền. Tiếp đó, ấn nút <b>Mua ngay</b> để mở kênh TikTok của Tui3Gang.</p>
        <img className='max-w-72 md:max-w-80 mx-auto block my-5' src='/images/guides/cach-nhan-tien-hoan-tiktok-shop-2.png' alt='Cách nhận tiền hoàn TikTok Shop 2' />
        <p className='my-3'><b>Bước 3:</b> Tại kênh TikTok của Tui3Gang, bạn chọn vào icon giỏ hàng sẽ thấy sản phẩm bạn cần mua. Nếu không thấy, bạn hãy chọn vào <b>"Phần trưng bày"</b> là sẽ thấy sản phẩm ở đó. Bây giờ bạn bấm vào sản phẩm rồi thêm vào giỏ hàng và đặt hàng áp mã giảm giá TikTok như bình thường.</p>
        <p className='my-3'>Sau khi đặt hàng xong khoảng 30 phút bạn sẽ thấy đơn hàng hoàn tiền ở trong mục <b>"Lịch sử hoàn tiền"</b>. Bạn có thể xem thêm <Link to="/huong-dan" className='underline'>hướng dẫn chi tiết hơn ở đây</Link>.</p>
        <img className='max-w-72 md:max-w-80 mx-auto block my-5' src='/images/guides/cach-nhan-tien-hoan-tiktok-shop-3.png' alt='Cách nhận tiền hoàn TikTok Shop 3' />
        <p className='my-3'>Trên đây là tổng hợp các mã giảm giá TikTok mới nhất. Đồng thời, Tui3Gang cũng hướng dẫn bạn cách lấy mã giảm giá TikTok Shop và cách mua hàng nhận tiền hoàn từ TikTok thông qua Tui3Gang.Com. Chúc bạn mua sắm vui vẻ và tiết kiệm!</p>
    </>
  );
};

export default VoucherTikTok;
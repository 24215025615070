import React, { useState, useEffect } from 'react';

const NotiInstallApp = () => {
  const [visible, setVisible] = useState(false);
  const [isAndroid, setIsAndroid] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isAppInstalled, setIsAppInstalled] = useState(false);

  useEffect(() => {
    // Kiểm tra nếu ứng dụng đã được cài đặt
    const appInstalled = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;
    setIsAppInstalled(appInstalled);
    if (appInstalled) return; // Không hiển thị nếu ứng dụng đã được cài đặt

    // Kiểm tra loại thiết bị
    const userAgent = window.navigator.userAgent.toLowerCase();
    setIsAndroid(/android/.test(userAgent));
    setIsIOS(/iphone|ipad|ipod/.test(userAgent));
    setIsDesktop(/windows|macintosh|linux/.test(userAgent));

    // Lắng nghe sự kiện beforeinstallprompt để xử lý trên desktop và Android
    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault();
      setDeferredPrompt(event);

      // Hiển thị thông báo sau 4 giây nếu chưa hiển thị hôm nay
      const lastShownDate = localStorage.getItem('notiInstallAppDate');
      const today = new Date().toLocaleDateString();

      if (lastShownDate !== today) {
        const timer = setTimeout(() => {
          setVisible(true);
          localStorage.setItem('notiInstallAppDate', today);

          // Tự động ẩn thông báo sau 6 giây
          const hideTimer = setTimeout(() => setVisible(false), 6000);
          return () => clearTimeout(hideTimer);
        }, 4000);

        // Cleanup timer nếu component bị unmount
        return () => clearTimeout(timer);
      }
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    // Hiển thị thông báo cho iOS nếu không có deferredPrompt
    if (isIOS) {
      const lastShownDate = localStorage.getItem('notiInstallAppDate');
      const today = new Date().toLocaleDateString();

      if (lastShownDate !== today) {
        const timer = setTimeout(() => {
          setVisible(true);
          localStorage.setItem('notiInstallAppDate', today);

          // Tự động ẩn thông báo sau 6 giây
          const hideTimer = setTimeout(() => setVisible(false), 6000);
          return () => clearTimeout(hideTimer);
        }, 4000);

        return () => clearTimeout(timer);
      }
    }

    // Cleanup
    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, [isIOS]);

  const handleClick = () => {
    if (isAndroid && deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('Người dùng đã đồng ý cài đặt');
        } else {
          console.log('Người dùng đã từ chối cài đặt');
        }
        setDeferredPrompt(null);
      });
    } else if (isIOS) {
      window.open('/bai-viet/huong-dan-cai-djat-ung-dung-tui3gang.com', '_blank');
    } else if (isDesktop && deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('Người dùng đã đồng ý cài đặt');
        } else {
          console.log('Người dùng đã từ chối cài đặt');
        }
        setDeferredPrompt(null);
      });
    } else {
      window.open('/bai-viet/huong-dan-cai-djat-ung-dung-tui3gang.com', '_blank');
    }
  };

  // Không render component nếu ứng dụng đã được cài đặt hoặc không có deferredPrompt và không phải iOS
  if (isAppInstalled || (!deferredPrompt && !isIOS)) {
    return null;
  }

  // Không render component nếu không visible
  if (!visible) {
    return null;
  }

  return (
    <div 
      className="fixed top-1 right-1 bg-white border border-gray-300 rounded p-3 shadow-md items-center w-auto max-w-72 transition-opacity duration-500 cursor-pointer"
      onClick={handleClick}
    >
      <div className='flex'>
        <img src="/images/logo-tui3gang.png" alt="Thông báo" className="w-7 h-7" />
        <p className="font-bold">Cài đặt ứng dụng Tui3Gang</p>
      </div>
      <div className="text-xs mt-1">
        { (isAndroid && deferredPrompt) || (isDesktop && deferredPrompt) ? (
          <span>Nhấn vào đây để cài ứng dụng Tui3Gang.Com giúp truy cập nhanh hơn.</span>
        ) : (
          <span>Nhấn vào <span className='text-blue-800 font-bold'>Hướng dẫn cài đặt</span> để cài đặt và truy cập Tui3Gang.Com nhanh hơn.</span>
        )}
      </div>
    </div>
  );
};

export default NotiInstallApp;
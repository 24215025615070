import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { fetchProductInfo, resetProductInfo } from '../../store/shopcashbacks/getInfoProductShopeeSlice';
import { FaClipboard, FaCartShopping, FaShareNodes } from 'react-icons/fa6';
import SEOMeta from '../../components/SEOMeta';
import formatCurrency from '../utils/formatCurrency';

const ProductInfoComponent = () => {
    const dispatch = useDispatch();
    const [url, setUrl] = useState('');
    const user = useSelector((state) => state.profile.user);
    const idUser = user?.idUser || 'notlogin';
    const navigate = useNavigate(); // Sử dụng useHistory để chuyển hướng

    const inputRef = useRef(null);

    const loading = useSelector((state) => state.productShopee.loading);
    const productInfo = useSelector((state) => state.productShopee.productInfo);
    const error = useSelector((state) => state.productShopee.error);

    const handleFetchProductInfo = useCallback(() => {
        dispatch(fetchProductInfo({ url, idUser }));
    }, [dispatch, url, idUser]);

    useEffect(() => {
        if (url.trim() !== '') {
            handleFetchProductInfo();
        }
    }, [url, handleFetchProductInfo]);

    useEffect(() => {
        return () => {
            dispatch(resetProductInfo());
        };
    }, [dispatch]);

    const handlePasteFromClipboard = () => {
        navigator.clipboard.readText().then((clipboardText) => {
            setUrl(clipboardText.trim());
        }).catch((error) => {
            console.error('Failed to read clipboard text:', error);
        });
    };

    const handleInputChange = (e) => {
        setUrl(e.target.value);
    };

    const handleInputFocus = () => {
        setUrl('');
    };

    const handleCustomPaste = (e) => {
        e.preventDefault();
        navigator.clipboard.readText().then((clipboardText) => {
            if (url.trim() === '') {
                setUrl(clipboardText.trim());
            }
        }).catch((error) => {
            console.error('Failed to read clipboard text:', error);
        });
    };

    const handleClickBuyNow = () => {
        if (idUser === 'notlogin') {
            navigate('/login'); // Chuyển hướng tới trang /login nếu chưa đăng nhập
        } else {
            const urlProduct = `https://s.tui3gang.com${productInfo[0].shortUrl}`;
            window.open(`/redirect?url=${urlProduct}`);
        }
    };

    const handleShare = () => {
        if (navigator.share) {
            navigator.share({
                title: 'Sản phẩm này đang có giá tốt quá nè!',
                text: 'Bạn ơi! Chốt đơn sản phẩm này đi, giá đang tốt quá nè',
                url: `https://s.tui3gang.com${productInfo[0].shortUrl}`,
            }).then(() => {
                console.log('Successful share');
            }).catch((error) => {
                console.log('Error sharing:', error);
            });
        } else {
            console.log('Web Share API is not supported in this browser.');
        }
    };

    const truncateText = (text, length) => {
        if (text.length <= length) return text;
        return text.substring(0, length) + '...';
      };
    
    return (
        <div>
            <SEOMeta 
                title="Cashback Shopee - Mua Hàng Nhận Hoàn Tiền Shopee Tới 50%"
                description="Mua hàng trên Shopee thông qua Túi 3 Gang bạn sẽ được nhận hoàn tiền từ Shopee lên tới 40%. Nhớ tạo link Shopee và ấn Mua ngay để được nhận chiết khấu từ Shopee nhé."
            />
            <div className="w-full relative text-sm mb-4">
                <div className="w-full border border-green-600 absolute top-1/2 -translate-y-1/2"></div>
                <div className="flex justify-between p-2">
                    <div className="p-1 flex flex-col justify-between items-center gap-2">
                        <div className="flex justify-center items-center relative"><span>Ngày mua</span>
                        </div><span className="text-white bg-green-700 px-2 rounded">Hôm nay</span>
                    </div>
                    <div className="p-1 flex flex-col justify-between items-center">
                        <div className="flex justify-center items-center relative"><span>Ghi nhận</span>
                        </div><span className="text-white bg-green-700 px-2 rounded">Ngày mai</span>
                    </div>
                    <div className="p-1 flex flex-col justify-between items-center">
                        <div className="flex justify-center items-center relative"><span>Có thể rút</span>
                        </div><span className="text-white bg-green-700 px-2 rounded">7 ngày</span>
                    </div>
                </div>
            </div>
            <div className="max-w mx-auto p-3 bg-white rounded-lg shadow-md">
                <div className="flex items-center border border-green-700 rounded-md px-3 py-1">
                    <input
                        type="text"
                        className="w-full focus:outline-none"
                        placeholder="Nhập link sản phẩm Shopee"
                        value={url}
                        onChange={handleInputChange}
                        ref={inputRef}
                        onFocus={handleInputFocus}
                        onPaste={handleCustomPaste}
                    />
                    <button
                        className="ml-2 focus:outline-none flex items-center border border-gray-200 p-2 rounded-lg"
                        onClick={handlePasteFromClipboard}
                    >
                        <FaClipboard className="text-gray-500 hover:text-gray-600 cursor-pointer pr-1" /> Dán
                    </button>
                </div>

                {loading && <p className="mt-4 text-center text-gray-600">Đang tải thông tin hoàn tiền...</p>}
                {error && <p className="mt-4 p-1 rounded-md text-center text-red-600 bg-red-50">Hãy nhập đúng link sản phẩm để được hoàn tiền!</p>}
                {productInfo && Array.isArray(productInfo) && productInfo.length > 0 && (
                    <div className="mt-4">
                        <p className="font-bold text-lg py-3">Thông tin hoàn tiền</p>
                        {productInfo.map((product) => (
                            <div key={product.itemId} className="mb-2 p-2 bg-gray-50 rounded-md">
                                <div className="flex items-center mb-3">
                                    <img
                                        src={product.imageUrl}
                                        alt={product.productName}
                                        className="w-24 h-24 mr-4 rounded-md"
                                    />
                                    <div>
                                        <h2 className="text-md font-medium">{truncateText(product.productName, 50)}</h2>
                                        <p>Giá bán: <span className="px-1 py-[2px] rounded-lg text-fuchsia-600 bg-fuchsia-50">{formatCurrency(Number(product.priceMin))}</span></p>
                                        <p>Hoàn tiền đến: <span className="text-lg px-2 py-[2px] rounded-lg text-green-700 bg-green-100">{formatCurrency(product.commission)}</span></p>
                                    </div>
                                </div>
                                <div className="flex justify-center">
                                    <button
                                        className="px-8 py-1 bg-green-700 text-white rounded-md hover:bg-green-600 focus:outline-none muangay"
                                        onClick={handleClickBuyNow} // Sử dụng handleBuyNow để kiểm tra đăng nhập và mở trang Mua ngay
                                    >
                                        <FaCartShopping className="inline-block mr-2" /> Mua ngay
                                    </button>
                                    <button
                                        className="ml-4 px-4 py-1 text-green-700 rounded-md border border-green-700 hover:bg-green-200 focus:outline-none"
                                        onClick={handleShare}
                                    >
                                        <FaShareNodes className="inline-block mr-2" /> Chia sẻ
                                    </button>
                                </div>
                                <p className="mt-4 p-1 rounded-lg text-base text-center text-fuchsia-600 bg-fuchsia-50">Mua hàng hoặc chia sẻ link cho bạn bè mua hàng đều nhận được tiền hoàn!</p>
                            </div>
                        ))}
                        <p className="text-sm text-center text-orange-500">Mẹo: Tạo nhiều đơn sẽ được hoàn tiền nhiều hơn!</p>
                        <p className="text-sm text-center text-orange-500">*Lưu ý: Số tiền hoàn thực tế phụ thuộc vào giá trị đơn hàng của bạn. Ngoài ra, một số shop có giới hạn số tiền hoàn.</p>
                    </div>
                )}
                {!loading && !error && (!productInfo || (Array.isArray(productInfo) && productInfo.length === 0)) && (
                    <>
                        <p className="mt-4 p-1 rounded-lg text-center text-fuchsia-600 bg-fuchsia-50">Nhập link sản phẩm Shopee để check hoàn tiền.</p>
                        <p className="mt-1 p-1 rounded-lg text-center">👉Xem hướng dẫn <Link to="/huong-dan" className='underline'>ở đây</Link>.</p>
                    </>
                )}
            </div>
            <div className="mb-3">
                <h2 className="font-bold pt-5 pb-2">Những lưu ý khi mua hàng hoàn tiền Shopee</h2>
                <div>
                    <div className="mb-3 py-3 px-1 w-full bg-white relative border rounded-md drop-shadow-md">
                        <h3 className="font-bold pb-3">🎯Điều kiện để được ghi nhận hoàn tiền</h3>
                        <p className="mb-2 text-sm ml-2">✅Cần phải “Dán link” sản phẩm Shopee để kiểm tra sản phẩm có được hoàn tiền hay không. Nếu có thì phải ấn vào “Mua ngay” và “Đặt hàng” luôn để được ghi nhận hoàn tiền nhiều nhất.</p>
                        <p className="mb-2 text-sm ml-2">✅Mỗi lần click nút “Mua ngay”, hệ thống chỉ ghi nhận hoàn tiền cho 01 đơn hàng duy nhất (đơn hàng có thể gồm nhiều sản phẩm).</p>
                        <p className="mb-2 text-sm ml-2">✅Để được ghi nhận cho đơn hàng tiếp, bạn phải thao tác dán link sản phẩm và ấn “Mua ngay” trước khi đặt đơn hàng mới.</p>
                        <p className="mb-2 text-sm ml-2">✅Không click vào link Shopee trên các ứng dụng, trang web khác trước khi đặt hàng. Vì sẽ khiến Tui3Gang không thể ghi nhận đơn hoàn tiền cho bạn.</p>
                        <p className="mb-2 text-sm ml-2">✅Nếu mua nhiều sản phẩm cùng shop, chỉ cần dán 01 link sản phẩm cần mua và chọn “Mua ngay”. Sau đó, thêm các sản phẩm còn lại vào giỏ hàng và tiến hành đặt đơn.</p>
                        <p className="mb-2 text-sm ml-2">✅Nếu mua nhiều sản phẩm của các shop khác nhau, bạn nên tách mỗi shop thành 01 đơn hàng riêng để nhận được tiền hoàn nhiều nhất.</p>
                    </div>
                    <div className="mb-3 py-3 px-1 w-full bg-white relative border rounded-md drop-shadow-md">
                        <h3 className="font-medium pb-3">❌Trường hợp không được ghi nhận hoàn tiền</h3>
                        <p className="mb-2 text-sm ml-2">🚫Click link Mua ngay sau khi đặt hàng.</p>
                        <p className="mb-2 text-sm ml-2">🚫Đơn hàng đổi trả hoặc hủy đơn từ phía bạn hoặc Shopee.</p>
                        <p className="mb-2 text-sm ml-2">🚫Click vào link Shopee trên các nền tảng khác trước khi đặt đơn.</p>
                        <p className="mb-2 text-sm ml-2">🚫Thêm sản phẩm vào giỏ hàng trước rồi mới bấm Mua ngay có thể khiến bạn không được ghi nhận hoàn tiền.</p>
                        <p className="mb-2 text-sm ml-2">🚫Các trường hợp gian lận hoặc nghi ngờ gian lận được phát hiện bởi hệ thống của Shopee.</p>
                        <p className="mb-2 text-sm ml-2">🚫Một số sản phẩm, ngành hàng không được hoàn tiền theo chính sách của Shopee.</p>
                        <p className="mb-2 text-sm ml-2">🚫Đơn hàng được gắn tag Shopee Live và Shopee Video tỉ lệ cao là không được ghi nhận hoàn tiền.</p>
                        <p className="mb-2 text-sm ml-2">🚫Mọi quyền quyết định cuối cùng về việc ghi nhận hoàn tiền thuộc về Shopee.</p>
                    </div>
                    <div className="mb-3 py-3 px-1 w-full bg-white relative border rounded-md drop-shadow-md">
                        <p className="my-2 text-sm ml-2">👉Xem chi tiết chính sách hoàn tiền của Shopee <Link to="/bai-viet/chinh-sach-hoan-tien-shopee" className='underline'>ở đây</Link></p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductInfoComponent;
import axios from 'axios';
import { BASE_URL } from '../configs';

export const changePassword = async (data, token) => {
  try {
    const response = await axios.post(`${BASE_URL}/change-password`, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response;
  } catch (error) {
    throw error;
  }
};
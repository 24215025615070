import React, { useEffect, useState } from 'react';
import { getTopUsers } from '../../api/user/topUserMonthApi';
import formatCurrency from '../utils/formatCurrency';
import { formattedMonthYear } from '../utils/formatTimeNow';
import { FaUser } from 'react-icons/fa6';

const rankMap = {
  0: 'Tân binh',
  1: 'Sơ cấp',
  2: 'Cao thủ',
  3: 'Đại cao thủ',
  4: 'Chiến thần',
  5: 'Huyền thoại'
};

const indexRank = {
  0: 'font-bold text-2xl sm:text-3xl text-purple-500',
  1: 'font-bold text-xl sm:text-2xl text-red-500',
  2: 'font-bold text-lg sm:text-xl text-amber-500',
  3: 'font-bold sm:text-lg text-yellow-400',
  4: 'font-bold sm:text-lg text-green-500',
  5: ''
};

const rankColors = {
  0: 'bg-gradient-to-r from-lime-300 to-green-600',
  1: 'bg-gradient-to-r from-teal-300 to-cyan-600',
  2: 'bg-gradient-to-r from-cyan-400 to-blue-600',
  3: 'bg-gradient-to-r from-fuchsia-400 to-pink-600',
  4: 'bg-gradient-to-r from-orange-400 via-amber-400 to-yellow-600',
  5: 'bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500'
};

const rankAnimation = {
  0: '',
  1: '',
  2: '',
  3: 'absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-white to-transparent animate-shimmer',
  4: 'absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-white to-transparent animate-shimmer',
  5: 'absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-white to-transparent animate-shimmer'
};

const TopUsers = () => {
  const [topUsers, setTopUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTopUsers = async () => {
      setLoading(true);
      const users = await getTopUsers();
      
      // Sắp xếp người dùng theo totalRefundCredit từ lớn đến nhỏ
      const sortedUsers = users.sort((a, b) => b.totalRefundCredit - a.totalRefundCredit);
      
      setTopUsers(sortedUsers);
      setLoading(false);
    };

    fetchTopUsers();
  }, []);

  return (
    <div className="container mx-auto mt-3">
      <div className="bg-white shadow-md rounded-lg p-3">
        <p className="text-xl sm:text-2xl font-bold mb-3 text-center">
          Top 10 cao thủ hoàn tiền {formattedMonthYear}
        </p>
        <div className="overflow-x-auto">
          {loading ? (
            <div className="text-center py-10 text-lg font-medium">Đang tải thông tin...</div>
          ) : (
            <table className="min-w-full">
              <tbody>
                {topUsers.map((user, index) => (
                  <tr key={user.idUser} className="border-t">
                    <td className={`px-2 text-center ${indexRank[index]}`}>{index + 1}</td>
                    <td className="py-2 flex items-center justify-between w-full">
                      <div className="flex items-center w-2/3 sm:w-1/2">
                        <div className="bg-gradient-to-r from-cyan-500 to-blue-500 rounded-full p-2">
                          <FaUser size={18} className="text-white" />
                        </div>
                        <div className="ml-2 font-medium">
                          <div>{user.fullName || `User-${user.idUser}`}</div>
                          <div
                            className={`text-sm text-white ${rankColors[user.rankUser]} inline-block rounded-md px-2 relative overflow-hidden`}
                          >
                            {rankMap[user.rankUser]}
                            <div className={rankAnimation[user.rankUser]}></div>
                          </div>
                        </div>
                      </div>
                      <div className="py-2 text-right w-1/3 sm:w-1/2">
                        <span className="text-white px-2 py-1 rounded-md bg-gradient-to-r from-cyan-500 to-blue-500">
                          {formatCurrency(user.totalRefundCredit)}
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopUsers;
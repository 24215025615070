import React from 'react';
import { Link } from 'react-router-dom';
import ListVoucherLazada from './ListVoucherLazada';
import FormLazadaCashback from '../shopcashbacks/FormLazadaCashback';
import SEOMeta from '../SEOMeta';
import {formattedMonthYear, formattedDate} from '../utils/formatTimeNow';
import BoxJoinGroup from '../other/BoxJoinGroup';

const VoucherLazada = () => {

  return (
    <>
        <SEOMeta 
          title={`Mã giảm giá Lazada Độc Quyền tháng ${formattedMonthYear}`}
          description={`Mã giảm giá Lazada tháng ${formattedMonthYear} còn dùng được 100%. Chia sẻ voucher Lazada Độc Quyền áp toàn sàn, số lượng có hạn. Mua hàng giảm giá còn được hoàn tiền.`}
        />
        <p className="my-3">Trang web chia sẻ <b>mã giảm giá Lazada</b> hoàn toàn miễn phí! Các mã voucher, chương trình khuyến mãi từ Lazada được cập nhật liên tục mỗi khung giờ giúp bạn lấy được những mã giảm giá mới nhất và còn lượt dùng.</p>
        <p className="my-3">Đừng quên truy cập <b>Tui3Gang.Com</b> mỗi khi có ý định mua sắm trên Lazada. Với Túi 3 Gang bạn không chỉ nhận được mã giảm giá mà còn được hoàn tiền mỗi khi mua hàng.</p>
        {/* Khung hiển thị hoàn tiền giống mã giảm giá */}
        <div className="p-1 border rounded-md shadow-md flex flex-col bg-white">
            <div className="flex">
                <div className="w-1/4 flex flex-col items-center justify-center">
                    <img src="/images/logo-lazada-coupon.png" alt="Hoàn tiền Lazada" className="w-11 h-auto rounded-xl mb-1 bg-[#0a00c5]" />
                    <p className="text-md">Lazada</p>
                </div>
                <div className="w-full px-2 flex flex-col justify-between">
                    <p className="text-md font-bold">Hoàn tiền đến 40% giá trị đơn hàng</p>
                    <div className="flex justify-between">
                        <div className="items-start">
                        <p className="text-sm text-gray-500">#Áp dụng với tất cả user</p>
                        <a href="/huong-dan" className="text-sm text-green-700 underline">Xem hướng dẫn</a>
                        </div>
                        <div className="text-sm flex items-center justify-end">
                            <Link to="/lazada">
                                <button className="text-green-700 font-medium px-4 py-1 rounded bg-green-100 getvoucher">Nhận ngay</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <p className="text-2xl font-semibold my-5 p-2">Mã giảm giá Lazada ngày {formattedDate}</p>
        <FormLazadaCashback />
        <ListVoucherLazada />
        <BoxJoinGroup />
    </>
  );
};

export default VoucherLazada;
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { getUrlProductAccesstradeApi } from '../../api/shopcashbacks/getUrlProductAccesstradeApi';

const initialState = {
    loading: false,
    error: null,
    productInfo: null,
};

export const fetchProductInfo = createAsyncThunk(
    'productAccesstrade/fetchProductInfo',
    async ({ url, idUser }) => {
        const response = await getUrlProductAccesstradeApi({ url, idUser });
        return response;
    }
);

export const resetProductInfo = createAction('productAccesstrade/resetProductInfo');

const productAccesstradeSlice = createSlice({
    name: 'productAccesstrade',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProductInfo.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchProductInfo.fulfilled, (state, action) => {
                state.loading = false;
                state.productInfo = action.payload;
                state.error = action.payload.error;
            })
            .addCase(fetchProductInfo.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.error;
            })
            .addCase(resetProductInfo, (state) => {
                state.productInfo = null; // Reset productInfo to null
            });
    },
});

export default productAccesstradeSlice.reducer;
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchProductInfo, resetProductInfo } from '../../store/shopcashbacks/getUrlProductAccesstradeSlice';
import { FaClipboard, FaCartShopping, FaShareNodes, FaAngleDown } from 'react-icons/fa6';
import SEOMeta from '../../components/SEOMeta';

const ProductInfoComponent = () => {
    const dispatch = useDispatch();
    const [url, setUrl] = useState('');
    const [showParagraphId, setShowParagraphId] = useState([]);
    const user = useSelector((state) => state.profile.user);
    const idUser = user?.idUser || 'notlogin';
    const navigate = useNavigate();

    const inputRef = useRef(null);

    const loading = useSelector((state) => state.productAccesstrade.loading);
    const productInfo = useSelector((state) => state.productAccesstrade.productInfo);
    const error = useSelector((state) => state.productAccesstrade.error);

    const handleFetchProductInfo = useCallback(() => {
        dispatch(fetchProductInfo({ url, idUser }));
    }, [dispatch, url, idUser]);

    useEffect(() => {
        if (url.trim() !== '') {
            handleFetchProductInfo();
        }
    }, [url, handleFetchProductInfo]);

    useEffect(() => {
        return () => {
            dispatch(resetProductInfo());
        };
    }, [dispatch]);

    const handlePasteFromClipboard = () => {
        navigator.clipboard.readText().then((clipboardText) => {
            setUrl(clipboardText.trim());
        }).catch((error) => {
            console.error('Failed to read clipboard text:', error);
        });
    };

    const handleInputChange = (e) => {
        setUrl(e.target.value);
    };

    const handleInputFocus = () => {
        setUrl('');
    };

    const handleCustomPaste = (e) => {
        e.preventDefault();
        navigator.clipboard.readText().then((clipboardText) => {
            if (url.trim() === '') {
                setUrl(clipboardText.trim());
            }
        }).catch((error) => {
            console.error('Failed to read clipboard text:', error);
        });
    };

    const handleClickBuyNow = () => {
        if (idUser === 'notlogin') {
            navigate('/login'); // Chuyển hướng tới trang /login nếu chưa đăng nhập
        } else {
            const urlProduct = `https://s.tui3gang.com${productInfo.shortUrl}`;
            window.open(urlProduct, '_blank');
        }
    };

    const handleShare = () => {
        if (navigator.share) {
            navigator.share({
                title: 'Sản phẩm này đang có giá tốt quá nè!',
                text: 'Bạn ơi! Chốt đơn sản phẩm này đi, giá đang tốt quá nè',
                url: `https://s.tui3gang.com${productInfo.shortUrl}`,
            }).then(() => {
                console.log('Successful share');
            }).catch((error) => {
                console.log('Error sharing:', error);
            });
        } else {
            console.log('Web Share API is not supported in this browser.');
        }
    };
    
    const handleClickShowParagraph = (index) => {
        setShowParagraphId((preshowParagraphId) => {
          if (preshowParagraphId.includes(index)) {
            return preshowParagraphId.filter((i) => i !== index);
          } else {
            return [...preshowParagraphId, index];
          }
        });
    };

    const itemFaqs = [
        {
          title: '1. Chính sách hoàn tiền của Thế Giới Di Động và Điện Máy Xanh',
          content: 'Để được ghi nhận hoàn tiền từ Thế Giới Di Động và Điện Máy Xanh bạn phải "Nhập link trang sản phẩm" vào ô bên trên và ấn "Mua ngay". Nếu bạn nhập các link Trang chủ, Trang tin tức, Trang khuyến mãi,... sẽ không được ghi nhận hoàn tiền. Ngoài ra, có một số sản phẩm đặc thù sẽ ghi nhận số tiền hoàn là 0.',
        },
        {
          title: '2. Chính sách hoàn tiền của nhà thuốc Long Châu',
          content: 'Rất vui khi hầu hết các sản phẩm của nhà thuốc Long Châu đều được hoàn tiền. Bạn chỉ cần dán link sản phẩm và ấn "Mua ngay" để được ghi nhận đơn hàng. Nhưng, theo chính sách của nhà thuốc Long Châu thì đơn hàng sẽ không hiển thị lên sau 24H mà chỉ được cập nhật vào kỳ đối soát hàng tháng. Tuy nhiên, bạn cứ yên tâm là bạn sẽ được ghi nhận đủ đơn hàng hoàn tiền.',
        },
        {
          title: '3. Chính sách hoàn tiền của FPT Shop',
          content: 'FPT Shop hỗ trợ hoàn tiền cho các sản phẩm thuộc ngành hàng chủ lực như Điện thoại, Máy tính bảng, Đồng hồ thông minh, Laptop - PC, Thiết bị gia dụng, Điện máy và Phụ kiện. Tuy nhiên, các sản phẩm của Apple không được hỗ trợ hoàn tiền.',
        },
        {
          title: '4. Chính sách hoàn tiền của các trang web khác',
          content: 'Hiện tại, chúng tôi đang liên kết với rất nhiều đối tác để hỗ trợ hoàn tiền cho người dùng. Bạn cần mua hàng ở trang web nào hãy nhập link website đó ở trên, nếu có hỗ trợ hoàn tiền sẽ hiển thị nút "Mua ngay". Đây là danh sách các web hỗ trợ hoàn tiền: happyskin.vn, thshop.vn, mykingdom.com.vn, mediamart.vn, samsung.com, watsons.vn, adidas.com.vn, lixibox.com, thefaceshop.com.vn, concung.com, juno.vn, mia.vn, fahasa.com, fado.vn, trip.com, vietjetair.com, traveloka.com, bestprice.vn, klook.com, travel.com.vn, vntrip.vn, mytour.vn, shop.fpt.vn, mediamart.vn,...updating...',
        },
      ];
    
    return (
        <div>
            <SEOMeta 
                title="Cashback Online - Hoàn Tiền Khi Mua Sắm Online Tới 60%"
                description="Mua hàng online thông qua Túi 3 Gang bạn sẽ được nhận hoàn tiền từ rất nhiều trang web như Điện Máy Xanh, Thế Giới Di Động, Nhà Thuốc Long Châu, Tiki, Fado,..."
            />
            <div className="w-full relative text-sm mb-4">
                <div className="w-full border border-green-600 absolute top-1/2 -translate-y-1/2"></div>
                <div className="flex justify-between p-2">
                    <div className="p-1 flex flex-col justify-between items-center gap-2">
                        <div className="flex justify-center items-center relative"><span>Ngày mua</span>
                        </div><span className="text-white bg-green-700 px-2 rounded">Hôm nay</span>
                    </div>
                    <div className="p-1 flex flex-col justify-between items-center">
                        <div className="flex justify-center items-center relative"><span>Ghi nhận</span>
                        </div><span className="text-white bg-green-700 px-2 rounded">Ngày mai</span>
                    </div>
                    <div className="p-1 flex flex-col justify-between items-center">
                        <div className="flex justify-center items-center relative"><span>Có thể rút</span>
                        </div><span className="text-white bg-green-700 px-2 rounded">30 ngày</span>
                    </div>
                </div>
            </div>
            <div className="max-w mx-auto p-3 bg-white rounded-lg shadow-md">
                <div className="flex items-center border border-green-700 rounded-md px-3 py-1">
                    <input
                        type="text"
                        className="w-full focus:outline-none"
                        placeholder="Nhập link sản phẩm"
                        value={url}
                        onChange={handleInputChange}
                        ref={inputRef}
                        onFocus={handleInputFocus}
                        onPaste={handleCustomPaste}
                    />
                    <button
                        className="ml-2 focus:outline-none flex items-center border border-gray-200 p-2 rounded-lg"
                        onClick={handlePasteFromClipboard}
                    >
                        <FaClipboard className="text-gray-500 hover:text-gray-600 cursor-pointer pr-1" /> Dán
                    </button>
                </div>

                {loading && <p className="mt-4 text-center text-gray-600">Đang tải thông tin hoàn tiền...</p>}
                {error && <p className="mt-4 p-1 rounded-lg text-center text-red-600 bg-red-50">Trang web bạn nhập hiện tại không được hỗ trợ hoàn tiền!</p>}
                {productInfo && !error && !loading &&(
                    <div className="mt-4">                        
                        <div className="mb-4 p-4 bg-gray-100 rounded-lg">
                            <div>
                                <p className="text-center font-bold text-lg">Hey! Trang web <span className="p-1 rounded-lg text-center text-green-700 bg-green-50">{productInfo.domain }</span> có hỗ trợ hoàn tiền.</p>
                                <p className="mb-4 p-1 rounded-lg text-base text-center text-fuchsia-600 bg-fuchsia-50">Mua hàng hoặc chia sẻ link cho bạn bè mua hàng đều nhận được tiền hoàn!</p>
                            </div>
                            <div className="flex justify-center">
                                <button
                                    className="px-8 py-2 bg-green-700 text-white rounded-md hover:bg-green-600 focus:outline-none muangay"
                                    onClick={handleClickBuyNow} // Sử dụng handleBuyNow để kiểm tra đăng nhập và mở trang Mua ngay
                                >
                                    <FaCartShopping className="inline-block mr-2" /> Mua ngay
                                </button>
                                <button
                                    className="ml-4 px-4 py-1 text-green-700 rounded-md border border-green-700 hover:bg-green-200 focus:outline-none"
                                    onClick={handleShare}
                                >
                                    <FaShareNodes className="inline-block mr-2" /> Chia sẻ
                                </button>
                            </div>
                        </div>
                        <p className="text-sm text-center text-orange-500">*Trang web có hỗ trợ hoàn tiền nhưng có thể không phải tất cả các sản phẩm đều được hoàn. Ấn nút Mua hàng và kiểm tra đơn hoàn tiền trong tài khoản để biết chính xác.</p>
                    </div>
                )}
                {!loading && !error && (!productInfo) && (
                    <p className="mt-4 p-1 rounded-lg text-center text-fuchsia-600 bg-fuchsia-50">Nhập link trang web để kiểm tra xem có được hoàn tiền không.</p>
                )}
            </div>
            <div className="mb-3">
                <h2 className="font-bold pt-5 pb-2">Thông tin chính sách hoàn tiền</h2>
                <div>
                    {itemFaqs.map((item, index) => (
                        <div key={index} className="mb-3 p-3 w-full bg-white relative border rounded-md drop-shadow-md">
                        <h3 className="font-medium pb-3 cursor-pointer flex" onClick={() => handleClickShowParagraph(index)}>
                            {item.title} <FaAngleDown className="ml-auto text-gray-400 hover:text-green-700" />
                        </h3>
                        {showParagraphId.includes(index) && (
                            <p className="px-2">
                            {item.content}
                            </p>
                        )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ProductInfoComponent;
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { fetchPostBySlug } from '../../api/post/getListPostApi.js';
import Quill from 'quill';
import SEOMeta from '../../components/SEOMeta';
import 'quill/dist/quill.bubble.css';

const PostDetail = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const quillRef = useRef(null);

  const loadPost = useCallback(async () => {
    try {
      const data = await fetchPostBySlug(slug);
      setPost(data);
    } catch (error) {
      console.error('Failed to fetch post:', error);
    }
  }, [slug]);

  useEffect(() => {
    loadPost();
  }, [loadPost]);

  useEffect(() => {
    if (post && quillRef.current) {
      const quill = new Quill(quillRef.current, {
        theme: 'bubble',
        readOnly: true,
        modules: {
          toolbar: false,
        },
      });
      quill.root.innerHTML = post.content;
    }
  }, [post]);

  if (!post) {
    return <div>Loading...</div>;
  }

  const stripHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };

  return (
    <div>
      <SEOMeta 
        title={`${post.title}`}
        description={`${stripHtml(post.content).substring(0, 160)}`}
      />
      <div className="container mx-auto p-2">
        <h1 className="text-2xl font-bold mb-4">{post.title}</h1>
        <div ref={quillRef}></div>
      </div>
    </div>
  );
};

export default PostDetail;
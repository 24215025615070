import axios from 'axios';
import { BASE_URL } from '../configs';

// Lấy danh sách bài viết
export const fetchPosts = async (page, limit) => {
  const response = await axios.get(`${BASE_URL}/api/v1/post?page=${page}&limit=${limit}`);
  return response.data;
};

// Lấy chi tiết bài viết theo slug
export const fetchPostBySlug = async (slug) => {
  const response = await axios.get(`${BASE_URL}/api/v1/post/${slug}`);
  return response.data;
};
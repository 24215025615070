import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { forgotPassword } from '../../api/user/forgotPassword';

export const forgotPasswordThunk = createAsyncThunk(
  'forgotPassword',
  async (email, thunkAPI) => {
    try {
      const response = await forgotPassword(email);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState: {
    isLoading: false,
    error: null,
    passwordResetRequested: false,
    passwordResetConfirmed: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(forgotPasswordThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(forgotPasswordThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.passwordResetRequested = true;
      })
      .addCase(forgotPasswordThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default forgotPasswordSlice.reducer;
import axios from 'axios';
import { BASE_URL } from '../configs';

// Hàm xác nhận mã đăng nhập một lần
export const verifyOneTimeCode = async (oneTimeCode) => {
  try {
    const response = await axios.post(`${BASE_URL}/login-with-code-otp`, { loginCode: oneTimeCode });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Lỗi xác nhận mã đăng nhập');
  }
};

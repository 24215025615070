import axios from 'axios';
import Cookies from 'js-cookie';
import { BASE_URL } from '../configs';

export const fetchGiftsApi = async () => {
    const token = Cookies.get('token');
    const response = await axios.get(`${BASE_URL}/api/v1/user/gift-list`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
};

export const redeemGiftVoucherApi = async (voucherType) => {
    const token = Cookies.get('token');
    const response = await axios.post(`${BASE_URL}/api/v1/user/get-gift-voucher`, { voucherType }, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
};
import axios from 'axios';
import { BASE_URL } from './configs';

export const fetchShopeeSpinners = async () => {
  const currentTime = Date.now();
  try {
    const response = await axios.get(`${BASE_URL}/api/v1/shopee/shopee-spinner-coin/${currentTime}`);
    return response.data.data.allSpinner;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
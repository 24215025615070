import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchFlashSaleVouchersApi, redeemGiftVoucherApi } from '../../api/user/getGiftFlashSaleApi';

// Thunk để lấy thông tin các quà tặng trong flash sale
export const fetchFlashSaleVouchers = createAsyncThunk('getGiftsFlashSale/fetchFlashSaleVouchers', async (_, { rejectWithValue }) => {
    try {
        const response = await fetchFlashSaleVouchersApi();
        return response;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

// Thunk để đổi voucher trong flash sale
export const redeemVoucher = createAsyncThunk('getGiftsFlashSale/redeemVoucher', async (voucherType, { rejectWithValue }) => {
    try {
        const response = await redeemGiftVoucherApi(voucherType);
        return response;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

// Tạo slice cho flash sale
const getGiftsFlashSaleSlice = createSlice({
    name: 'getGiftsFlashSale',
    initialState: {
        flashSaleList: [],
        status: 'idle',
        error: null,
        voucherCode: null,
    },
    reducers: {
        resetVoucherCode(state) {
            state.voucherCode = null; // Reset voucherCode về null
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchFlashSaleVouchers.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchFlashSaleVouchers.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.flashSaleList = action.payload;
            })
            .addCase(fetchFlashSaleVouchers.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(redeemVoucher.fulfilled, (state, action) => {
                state.voucherCode = action.payload.voucherCode;
            })
            .addCase(redeemVoucher.rejected, (state, action) => {
                state.error = action.payload;
            });
    },
});

export const { resetVoucherCode } = getGiftsFlashSaleSlice.actions;
export default getGiftsFlashSaleSlice.reducer;
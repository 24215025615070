import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const AffiliatedPartners = ({ images }) => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
        {
          breakpoint: 640, // Điểm bẻ gãy cho màn hình có độ rộng 640px
          settings: {
            slidesToShow: 5, // Hiển thị 5 ảnh cùng 1 hàng trên màn hình có độ rộng 640px
            slidesToScroll: 3, // Cuộn 3 ảnh cùng lúc trên màn hình có độ rộng 640px
          }
        }
      ]
  };

  return (
    <div className="max-w-full sm:max-w-[768px] mx-auto mb-2 p-1 sm:p-3">
        <p className="text-center text-white text-xl mb-5 p-2 rounded-lg bg-green-700">50+ đối tác liên kết</p>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="py-1 px-1">
            <img src={image.src} alt={`Partner-${index}`} className="w-20 sm:w-24 h-auto rounded-md" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default AffiliatedPartners;
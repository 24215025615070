import React from 'react';
import Header from './Header';
import Footer from './Footer';
import StickyFooterBar from './StickyFooterBar';
import StickyIconRight from './other/StickyIconRight';

const MainLayout = ({ children, isLoggedIn }) => {
    return (
      <div className="min-h-screen bg-gray-50 flex flex-col items-center">
        <Header isLoggedIn={isLoggedIn} />
        <main className="flex-grow container mx-auto p-3 max-w-[768px]">
          {children}
        </main>
        <StickyFooterBar />
        <StickyIconRight />
        <Footer />
      </div>
    );
  };
  
  export default MainLayout;
import React, { useState } from 'react';
import { findProductInVideo } from '../../api/searchVideoShopeeApi';
import truncateText from '../utils/truncateText';
import formatCurrency from '../utils/formatCurrency';

const FindProductInVideo = () => {
    const [keyword, setKeyword] = useState('');
    const [products, setProducts] = useState([]);
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');

    const handleSearch = async () => {
        try {
            const result = await findProductInVideo(keyword);
            setProducts(result);
            setError(''); // Xóa lỗi nếu có kết quả

            // Kiểm tra từ khóa trong kết quả trả về
            const keywordLowerCase = keyword.toLowerCase();
            const found = result.some(product => {
                const keywordProductLowerCase = product.keywordProduct.toLowerCase();
                return keywordLowerCase.split(' ').some(word => keywordProductLowerCase.includes(word));
              });

            if (found) {
                setMessage('Tuyệt vời! Sản phẩm bạn cần tìm có trong các video dưới đây nè!');
            } else {
                setMessage('Ố ồ! Chưa có video sản phẩm bạn tìm. Bạn có thể tham khảo các video sản phẩm dưới đây.');
            }
        } catch (err) {
            setError(err.message);
            setProducts([]);
        }
    };

    const handleClickProduct = (productUrl) => {
        const url = `https://s.shopee.vn/an_redir?origin_link=${productUrl}&affiliate_id=17345920097&sub_id=t3g-searchvideo`;
        window.open(url, '_blank');
    };

    const handleClickVideo = (videoUrl) => {
        const url = `https://s.shopee.vn/an_redir?origin_link=${videoUrl}&affiliate_id=17345920097&sub_id=t3g-searchvideo`;
        window.open(url, '_blank');
    };

    return (
        <div className="max-w-full mx-auto">
            <div className="bg-white shadow-md rounded-lg p-4 mb-4">
                <input
                    type="text"
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    placeholder="Nhập tên sản phẩm cần tìm..."
                    className="border border-gray-300 rounded-md p-2 w-full mb-4 focus:outline-none focus:ring-1 focus:ring-green-700"
                />
                <button
                    onClick={handleSearch}
                    className="w-full bg-green-700 hover:bg-green-600 text-white font-semibold p-2 rounded-lg transition duration-200"
                >
                    Tìm video sản phẩm Shopee
                </button>
            </div>

            {error && <div className="text-red-500 text-center mt-4">{error}</div>}
            {message && <div className="font-semibold text-center text-gray-700 mt-4">{message}</div>}

            <div className="mt-4">
                {products.length > 0 && (
                    <ul className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-2">
                        {products.map((product, index) => (
                            <li key={index} className="bg-white shadow-lg rounded-lg mb-4">
                                <div
                                    onClick={() => handleClickProduct(product.productUrl)}
                                    className="cursor-pointer"
                                >
                                    <img
                                        src={`https://down-vn.img.susercontent.com/file/${product.imageUrl}`}
                                        alt={product.nameProduct}
                                        className="w-full h-40 object-cover rounded-t-md mb-2"
                                    />
                                    <p className="mb-2 px-2">{truncateText(product.nameProduct, 45)}</p>
                                    <p className="px-2 text-red-500 font-semibold mb-4">
                                    <span className="text-gray-400 line-through">{formatCurrency(product.price)}</span> {formatCurrency(product.priceDiscount)}
                                    </p>
                                    <div
                                        onClick={() => handleClickVideo(product.videoUrl)}
                                        className="bg-green-700 text-white text-center mx-2 mb-3 p-1 rounded-lg transition duration-200 hover:bg-green-600 cursor-pointer"
                                    >
                                        Mở video ngay
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default FindProductInVideo;
import axios from 'axios';
import { BASE_URL } from '../configs';

export const register = async (userData) => {
  try {
    const response = await axios.post(`${BASE_URL}/register`, userData);
    return response;
  } catch (error) {
    throw error;
  }
};
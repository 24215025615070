import axios from 'axios';
import { BASE_URL } from '../configs';

export const getTopUsers = async (params) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/v1/user/top-user`, params);
    return response.data.topUsers;
  } catch (error) {
    console.error('Error fetching top users:', error);
    return [];
  }
};
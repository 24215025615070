import axios from 'axios';
import { BASE_URL } from '../configs';

const API_URL = `${BASE_URL}/api/v1/user`;

export const getTotalUsers = async () => {
    try {
        const response = await axios.get(`${API_URL}/count-total-user`);
        return response.data;
    } catch (error) {
        console.error('Error fetching total users:', error);
        throw error;
    }
};
import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FaUser, FaArrowRightFromBracket, FaBell } from 'react-icons/fa6';
import { markNotificationAsRead } from '../api/user/notificationApi';
import { getUserThunk } from '../store/user/profileSlice';
import BellNotifications from './other/BellNotification';

const Header = () => {
  const [showNotifications, setShowNotifications] = useState(false);
  const dispatch = useDispatch();
  const { isLoggedIn, notifications } = useSelector((state) => state.profile);
  const notificationRef = useRef(null);

  const toggleNotifications = () => {
    setShowNotifications((prev) => !prev);
  };

  const handleNotificationClick = async (notificationId) => {
    try {
      await markNotificationAsRead(notificationId);
      dispatch(getUserThunk()); // Tải lại thông tin người dùng để cập nhật thông báo
    } catch (error) {
      console.error('Lỗi khi đánh dấu thông báo là đã đọc:', error);
    }
  };

  const handleMarkAllAsRead = async () => {
    try {
      // Lặp qua tất cả các thông báo chưa đọc và đánh dấu là đã đọc
      const unreadNotificationIds = notifications
        .filter((notification) => notification.unread)
        .map((notification) => notification.notificationId);

      for (const notificationId of unreadNotificationIds) {
        await markNotificationAsRead(notificationId);
      }
      
      dispatch(getUserThunk()); // Tải lại thông tin người dùng để cập nhật thông báo
    } catch (error) {
      console.error('Lỗi khi đánh dấu tất cả thông báo là đã đọc:', error);
    }
  };

  // Xử lý click ra ngoài để đóng cửa sổ thông báo
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (notificationRef.current && !notificationRef.current.contains(event.target)) {
        setShowNotifications(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Lọc thông báo chưa đọc
  const unreadNotifications = notifications.filter((n) => n.unread);

  return (
    <header className="top-0 left-0 right-0 w-full border-b shadow-sm bg-white z-50">
      <div className="p-3 flex justify-between items-center max-w-[768px] w-full mx-auto">
        <div className="flex items-center">
          <Link to="/">
            <img src="/images/logo-tui3gang.png" alt="Túi 3 Gang" className="h-12" />
          </Link>
          <span className="text-2xl font-bold text-green-700">Tui3gang.com</span>
        </div>
        <div className="flex items-center relative" ref={notificationRef}>
          {isLoggedIn && (
            <button onClick={toggleNotifications} className="relative text-green-700">
              <FaBell size={28} className={unreadNotifications.length > 0 ? 'animate-bellShake' : ''} />
              {unreadNotifications.length > 0 && (
                <span className="absolute top-0 right-0 bg-orange-500 text-white text-xs rounded-full w-4 h-4 flex items-center justify-center">
                  {unreadNotifications.length}
                </span>
              )}
            </button>
          )}
          {isLoggedIn ? (
            <Link to="/profile" className="ml-4">
              <button className="flex items-center text-green-700">
                <FaUser size={28} />
              </button>
            </Link>
          ) : (
            <Link to="/login" className="ml-4">
              <button className="bg-white text-green-700 px-2 py-1 rounded flex items-center text-lg border border-green-700">
                <FaArrowRightFromBracket size={16} />&nbsp;Đăng nhập
              </button>
            </Link>
          )}
          {showNotifications && (
            <BellNotifications
              notifications={notifications}
              onNotificationClick={handleNotificationClick}
              onMarkAllAsRead={handleMarkAllAsRead} // Truyền hàm handleMarkAllAsRead
            />
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
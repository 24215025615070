import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ title, description }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="robots" content="index, follow" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
	  <meta property="og:image" content="https://tui3gang.com/images/cover-tui3gang-mxh.png" />
    </Helmet>
  );
};

export default SEO;
import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ImageSlider = ({ images }) => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className="max-w-[768px] mx-auto">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="py-1">
            <a href={image.link} rel="noopener noreferrer">
              <img src={image.src} alt={`Slide ${index}`} className="w-full h-auto rounded-md" />
            </a>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImageSlider;
import React, { useEffect, useState } from 'react';
import { fetchPosts } from '../../api/post/getListPostApi.js';
import SEOMeta from '../../components/SEOMeta';
import { Link } from 'react-router-dom';

const PostList = () => {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    loadPosts(currentPage);
  }, [currentPage]);

  const loadPosts = async (page) => {
    try {
      const data = await fetchPosts(page, 6);
      setPosts(data.posts);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error('Failed to fetch posts:', error);
    }
  };

  const stripHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container mx-auto p-4">
      <SEOMeta 
        title="Trang blog của Tui3Gang.Com"
        description="Cập nhật thông tin, bài viết mới nhất trên blog của Tui3Gang.Com."
      />
      <h2 className="text-2xl font-bold mb-4">Bài viết trên blog</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {posts.map((post) => (
          <Link to={`/bai-viet/${post.titleSlug}`} key={post._id} className="border rounded-lg overflow-hidden shadow-lg">
            <img src={post.thumbnail} alt={post.title} className="w-full h-48 object-cover" />
            <div className="p-4">
              <h3 className="text-lg font-bold mb-2">{post.title}</h3>
              <p className="text-gray-700 mb-4">{stripHtml(post.content).substring(0, 80)}...</p>
            </div>
          </Link>
        ))}
      </div>
      <div className="flex justify-center mt-4">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            className={`mx-1 px-3 py-1 border rounded ${currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-white text-blue-500'}`}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default PostList;
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { FaClipboard } from 'react-icons/fa6';
import { createTikTokCashbackOrder } from '../../api/shopcashbacks/getInfoProductTiktokShopApi';

const CreateCashbackOrderForm = () => {
  const [orderId, setOrderId] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  // Lấy idOfUser từ state.profile.idUser
  const user = useSelector((state) => state.profile.user);
  const idUser = user?.idUser || 'notlogin';

  const handlePaste = async () => {
    try {
      const text = await navigator.clipboard.readText();
      setOrderId(text);
    } catch (err) {
      console.error('Failed to read clipboard contents: ', err);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (idUser === 'notlogin') {
      navigate('/login');
      return;
    }
    try {
      const response = await createTikTokCashbackOrder(idUser, orderId);
      setMessage(`${response.data.message}`);
    } catch (error) {
      setMessage(`${error.response.data.message}`);
    }
  };

  return (
    <div className="max-w-full mx-auto my-8 p-3 border rounded-lg shadow-lg bg-white">
      <h2 className="text-xl font-semibold">Nhập số đơn TikTok - Nhận hoàn tiền</h2>
      <p className="text-md mb-4 p-1 text-gray-600">Áp dụng với đơn hàng từ TikTok Shop.</p>
      <form onSubmit={handleSubmit}>
        <div className="flex items-center border border-green-700 rounded-md px-3 py-1 mb-5">
          <input
            type="text"
            id="orderId"
            name="orderId"
            placeholder="Nhập Số đơn hàng TikTok"
            value={orderId}
            onChange={(e) => setOrderId(e.target.value)}
            className="w-full focus:outline-none"
            required
          />
          <button
            type="button"
            onClick={handlePaste}
            className="ml-2 focus:outline-none flex items-center border border-gray-200 p-2 rounded-lg"
          >
            <FaClipboard className="text-gray-500 hover:text-gray-600 cursor-pointer pr-1" /> Dán
          </button>
        </div>
        <button
          type="submit"
          className="w-full py-3 px-4 border border-transparent rounded-md shadow-sm text-md font-medium text-white bg-green-700 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
        >
          Tìm đơn hàng
        </button>
      </form>
      {message && (
        <div className={`mt-6 p-2 border rounded-md ${message.startsWith('Thành công') ? 'bg-green-100 border-green-300 text-green-700' : 'bg-red-100 border-red-300 text-red-700'}`}>
          {message}
        </div>
      )}
      <div className='mt-5'>
      <Link to="/tiktokshop" className='flex justify-center underline'>Về lại trang hoàn tiền TikTok</Link>
      </div>
    </div>
  );
};

export default CreateCashbackOrderForm;
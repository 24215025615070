import React from 'react';
import { Link } from "react-router-dom";
import { FaSquareFacebook, FaFacebookF, FaYoutube, FaTiktok } from "react-icons/fa6";
import NotiInstallApp from './other/NotiInstallApp';
import PopupImage from './other/PopupImage';

const Footer = () => {
  return (
    <footer className="p-4 w-full border-t shadow-sm bg-white mb-12">
      <div className="px-3 max-w-[768px] w-full mx-auto flex flex-col items-center sm:flex-row sm:items-start sm:justify-between">
        <div className="mb-4 sm:mb-0 sm:mr-4 sm:w-1/2 flex flex-col items-center sm:items-start">
          <div className="flex items-center mb-2">
            <Link to="/"><img src="/images/logo-tui3gang.png" alt="Túi 3 Gang" className="h-11" /></Link>
            <span className="text-2xl font-bold text-green-700">Tui3gang.com</span>
          </div>
          <p className="text-sm text-center sm:text-left px-2">Trang web chia sẻ mã giảm giá và hoàn tiền khi mua sắm online trên các trang TMĐT.</p>
		  <div className="flex text-sm px-2 my-2 justify-center sm:justify-end">
			  <Link to="/gioi-thieu" className="mr-2 hover:underline">Giới thiệu</Link>
			  <Link to="/huong-dan" className="mr-2 hover:underline">Hướng dẫn</Link>
			  <Link to="/bai-viet/huong-dan-cai-djat-ung-dung-tui3gang.com" className="hover:underline">Cài app Tui3Gang</Link>
		  </div>
        </div>
        <div className="flex flex-col items-center sm:flex-row sm:items-start sm:justify-between">
          <div className="flex flex-col items-center sm:items-end sm:ml-4">
            <div className="flex justify-center sm:justify-end mb-4 sm:mb-0">
              <Link to="https://www.facebook.com/groups/tui3gang.group" className="h-8 aspect-square flex justify-center items-center mx-1 text-green-700" target="_blank"><FaSquareFacebook size={20} /></Link>
              <Link to="https://www.facebook.com/tui3gangofficial" className="h-8 aspect-square flex justify-center items-center mx-1 text-green-700" target="_blank"><FaFacebookF size={20} /></Link>
              <Link to="https://www.youtube.com/@tui3gang.official" className="h-8 aspect-square flex justify-center items-center mx-1 text-green-700" target="_blank"><FaYoutube size={20} /></Link>
              <Link to="https://www.tiktok.com/@tui3gangshop" className="h-8 aspect-square flex justify-center items-center mx-1 text-green-700" target="_blank"><FaTiktok size={20} /></Link>
            </div>
            <div className="flex gap-2 text-sm mb-2 justify-center sm:justify-end">
              <Link to="/dieu-khoan" className="hover:underline">Điều khoản sử dụng</Link>
              <Link to="/chinh-sach" className="hover:underline">Chính sách bảo mật</Link>
            </div>
            <div className="text-sm">
              <p className="text-sm text-center sm:text-right">Copyright &copy; 2024 by Tui3Gang.Com</p>
            </div>
          </div>
        </div>
      </div>
      <NotiInstallApp />
      <PopupImage />
    </footer>
  );
};

export default Footer;
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchGiftsApi, redeemGiftVoucherApi } from '../../api/user/getGiftApi';

export const fetchGifts = createAsyncThunk('gifts/fetchGifts', async (_, { rejectWithValue }) => {
    try {
        const response = await fetchGiftsApi();
        return response;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const redeemVoucher = createAsyncThunk('gifts/redeemVoucher', async (voucherType, { rejectWithValue }) => {
    try {
        const response = await redeemGiftVoucherApi(voucherType);
        return response;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const giftSlice = createSlice({
    name: 'getGifts',
    initialState: {
        giftList: [],
        status: 'idle',
        error: null,
        voucherCode: null,
    },
    reducers: {
        resetVoucherCode(state) {
            state.voucherCode = null; // Reset voucherCode về null
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchGifts.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGifts.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.giftList = action.payload;
            })
            .addCase(fetchGifts.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(redeemVoucher.fulfilled, (state, action) => {
                state.voucherCode = action.payload.voucherCode;
            })
            .addCase(redeemVoucher.rejected, (state, action) => {
                state.error = action.payload;
            });
    },
});

export const { resetVoucherCode } = giftSlice.actions;
export default giftSlice.reducer;
import React from 'react';
import { SUPPORT_URL } from '../../api/configs';

const StickyIconRight = () => {

    const handleClick = () => {
        window.open(SUPPORT_URL, '_blank');
    };

    return (
        <div className="fixed z-10 right-2 bottom-20 flex flex-col items-end gap-1">
            <div className="text-center px-2 rounded-md drop-shadow-md bg-gradient-to-r from-lime-200 to-green-500">
                Hỗ trợ 24/7
                <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-white to-transparent animate-shimmer"></div>
            </div>
                <img
                    className="w-10 h-10 right-2 object-cover drop-shadow-sm cursor-pointer"
                    src="/images/brands/logo-facebook-messenger.png"
                    alt="Facebook"
                    onClick={handleClick}
                />
        </div>
    );
}

export default StickyIconRight;
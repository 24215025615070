import React, { useState, useEffect } from 'react';
import { getProductsByShop } from '../../api/deals/listProductByShopApi';
import { useParams, useNavigate } from 'react-router-dom';
import SEOMeta from '../SEOMeta';
import truncateText from '../utils/truncateText';
import formatCurrency from '../utils/formatCurrency';

const ListProductByShop = () => {
  const { shopNameSlug } = useParams();
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(12);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const data = await getProductsByShop(shopNameSlug, page, limit);
        if (data.products.length === 0) {
          // Nếu không có sản phẩm, hiển thị lỗi
          setError(true);
          setLoading(false);
        } else {
          setProducts(data.products);
          setTotalPages(data.totalPages);
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching products:', error);
        setError(true);
        setLoading(false);
      }
    };
    fetchProducts();
  }, [shopNameSlug, page, limit]);

  const handleProductClick = (dealSlug) => {
    navigate(`/deal/${dealSlug}`);
  };

  // Hiển thị trang 404 tùy chỉnh nếu không tìm thấy sản phẩm
  if (error) {
    return (
      <div className="container mx-auto py-4 text-center">
        <SEOMeta title="Không tìm thấy gian hàng" description="Gian hàng mà bạn tìm kiếm không tồn tại hoặc đã bị gỡ bỏ." />
        <h3 className="text-2xl font-bold mb-4">Không tìm thấy gian hàng này!</h3>
        <p className="text-gray-600 mb-1">Gian hàng bạn đang tìm không tồn tại hoặc đã bị gỡ bỏ.</p>
        <p className="text-center text-gray-600">
            Bạn hãy thử {' '}
            <span className="text-orange-600">
              tải lại trang
            </span>{' '}
            hoặc{' '}
            <span className="text-orange-600">
              quay về trang chủ.
            </span>
        </p>
        <button 
          onClick={() => navigate('/')}
          className="mt-4 px-4 py-2 bg-green-700 text-white rounded hover:bg-green-600"
        >
          Về trang chủ
        </button>
      </div>
    );
  }

  return (
    <div>
      {products.length > 0 && (
        <SEOMeta 
          title={`${products[0].shopName} Hoàn Tiền Khi Mua Qua Tui3Gang.Com`}
          description={`Khi mua sắm online trên gian hàng của ${products[0].shopName} thông qua Tui3Gang.Com bạn sẽ được hoàn tiền lên tới 10%.`}
        />
      )}

      <div className="container mx-auto py-4">
        {loading ? (
          <p>Đang tải thông tin sản phẩm...</p>
        ) : (
          <>
            <p className="mb-4">Tui3Gang.Com hợp tác cùng <b>{products[0].shopName}</b> mang đến chương trình hoàn tiền cực kỳ hấp dẫn cho bạn khi mua hàng online, không giới hạn mức hoàn tiền tối đa cho mỗi đơn hàng.</p>
            <p className="mb-4">Gợi ý các sản phẩm của shop được hoàn tiền👇</p>
            <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {products.map((product, index) => (
                <div
                  key={index}
                  className="bg-white shadow-md rounded-lg overflow-hidden cursor-pointer mb-2"
                  onClick={() => handleProductClick(product.dealSlug)}
                >
                  <img src={product.imageUrl} alt={product.productName} className="w-full h-auto object-cover" />
                  <div className="p-1">
                    <h3 className="mb-2 text-sm sm:text-base">{truncateText(product.productName, 40)}</h3>
                    <p className="text-black text-sm sm:text-base font-medium ml-2">{formatCurrency(Number(product.priceMin))}</p>
                    <p className="text-red-500 text-sm sm:text-base font-medium mb-1 ml-2">Hoàn tiền đến: {formatCurrency(Number(product.commission))}</p>
                  </div>
                </div>
              ))}
            </div>
            {/* Điều hướng phân trang */}
            <div className="mt-8 text-center">
              <button
                disabled={page <= 1}
                onClick={() => setPage(page - 1)}
                className="bg-green-700 text-white py-1 px-4 rounded-md mr-4 hover:bg-green-600 disabled:bg-gray-300"
              >
                Trước
              </button>
              <button
                disabled={page >= totalPages}
                onClick={() => setPage(page + 1)}
                className="bg-green-700 text-white py-1 px-4 rounded-md hover:bg-green-600 disabled:bg-gray-300"
              >
                Sau
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ListProductByShop;
import axios from 'axios';
import { BASE_URL } from '../configs';

export const getUser = (token) => {
  return axios.get(`${BASE_URL}/user`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const updateUser = (userData, token) => {
  return axios.post(`${BASE_URL}/edit-profile`, userData, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};
// PrivateRoute.js
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie'; // Import thư viện js-cookie

const PrivateRoute = () => {
  const isLoggedIn = useSelector((state) => state.profile.isLoggedIn);
  const token = Cookies.get('token'); // Lấy token từ cookies

  return isLoggedIn && token ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { FaClipboard, FaCartShopping, FaCirclePlus } from 'react-icons/fa6';
import SEOMeta from '../../components/SEOMeta';
import { getInfoProductLink } from '../../api/shopcashbacks/getInfoProductTiktokShopApi';
import formatCurrency from '../utils/formatCurrency';

const ProductCheckerAndAddToShowCase = () => {
    const [url, setUrl] = useState('');
    const user = useSelector((state) => state.profile.user);
    const idUser = user?.idUser || 'notlogin';
    const navigate = useNavigate();

    const inputRef = useRef(null);
    
    const [productData, setProductData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            if (url) {
                setLoading(true);
                setError('');
                try {
                    const data = await getInfoProductLink(url, idUser);  // Truyền thêm idUser khi gọi API
                    setProductData(data);
                } catch (err) {
                    setError(err.message);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchData();
    }, [url, idUser]);

    const handlePasteFromClipboard = () => {
        navigator.clipboard.readText().then((clipboardText) => {
            setUrl(clipboardText.trim());
        }).catch((error) => {
            console.error('Failed to read clipboard text:', error);
        });
    };
    
    const handleInputChange = (e) => {
        setUrl(e.target.value);
    };

    const handleInputFocus = () => {
        setUrl('');
    };

    const handleCustomPaste = (e) => {
        e.preventDefault();
        navigator.clipboard.readText().then((clipboardText) => {
            if (url.trim() === '') {
                setUrl(clipboardText.trim());
            }
        }).catch((error) => {
            console.error('Failed to read clipboard text:', error);
        });
    };

    const handleClickBuyNow = () => {
        if (idUser === 'notlogin') {
            navigate('/login'); // Chuyển hướng tới trang /login nếu chưa đăng nhập
        } else {
            const urlTiktok = 'https://www.tiktok.com/@tui3gangshop';
            window.open(urlTiktok, '_blank');
        }
    };

    const truncateText = (text, length) => {
        if (text.length <= length) return text;
        return text.substring(0, length) + '...';
    };

    return (
        <div>
            <SEOMeta 
                title="Cashback TikTok Shop - Mua Hàng Nhận Hoàn Tiền TikTok Shop Tới 50%"
                description="Mua hàng trên TikTok Shop thông qua Túi 3 Gang bạn sẽ được nhận hoàn tiền từ TikTok Shop lên tới 50%. Nhớ dán link sản phẩm TikTok Shop và ấn Mua ngay để được nhận chiết khấu từ TikTok Shop nhé."
            />
            <div className="w-full relative text-sm mb-4">
                <div className="w-full border border-green-600 absolute top-1/2 -translate-y-1/2"></div>
                <div className="flex justify-between p-2">
                    <div className="p-1 flex flex-col justify-between items-center gap-2">
                        <div className="flex justify-center items-center relative"><span>Ngày mua</span>
                        </div><span className="text-white bg-green-700 px-2 rounded">Hôm nay</span>
                    </div>
                    <div className="p-1 flex flex-col justify-between items-center">
                        <div className="flex justify-center items-center relative"><span>Ghi nhận</span>
                        </div><span className="text-white bg-green-700 px-2 rounded">Sau 1 giờ</span>
                    </div>
                    <div className="p-1 flex flex-col justify-between items-center">
                        <div className="flex justify-center items-center relative"><span>Có thể rút</span>
                        </div><span className="text-white bg-green-700 px-2 rounded">5 ngày</span>
                    </div>
                </div>
            </div>
            <div className="max-w mx-auto p-3 bg-white rounded-lg shadow-md">
                <div className="flex items-center border border-green-700 rounded-md px-3 py-1">
                    <input
                        type="text"
                        className="w-full focus:outline-none"
                        placeholder="Nhập link sản phẩm TikTok Shop"
                        value={url}
                        onChange={handleInputChange}
                        ref={inputRef}
                        onFocus={handleInputFocus}
                        onPaste={handleCustomPaste}
                    />
                    <button
                        className="ml-2 focus:outline-none flex items-center border border-gray-200 p-2 rounded-lg"
                        onClick={handlePasteFromClipboard}
                    >
                        <FaClipboard className="text-gray-500 hover:text-gray-600 cursor-pointer pr-1" /> Dán
                    </button>
                </div>

                {loading && <p className="mt-4 text-center text-gray-600">Đang tải thông tin hoàn tiền...</p>}
                {error && <p className="mt-4 p-1 rounded-md text-center text-fuchsia-600 bg-fuchsia-50">Hãy nhập đúng link sản phẩm để được hoàn tiền!</p>}
                {productData && (
                    <>
                    {productData.message ? (
                        <p className="mt-4 p-1 rounded-lg text-center text-red-600 bg-red-50">{productData.message}</p>
                    ) : (
                        <div className="mt-4">
                            <p className="font-bold text-lg py-3">Thông tin hoàn tiền</p>
                                <div className="mb-2 p-2 bg-gray-50 rounded-md">
                                    <div className="flex items-center mb-3">
                                        <img
                                            src={productData.thumb_url_list}
                                            alt={productData.title}
                                            className="w-24 h-24 mr-4 rounded-md"
                                        />
                                        <div>
                                            <h2 className="text-md font-medium">{truncateText(productData.title, 50)}</h2>
                                            <p>Giá bán: <span className="px-1 py-[2px] rounded-lg text-fuchsia-600 bg-fuchsia-50">{productData.format_price}</span></p>
                                            <p>Hoàn tiền đến: <span className="text-lg px-2 py-[2px] rounded-lg text-green-700 bg-green-100">{formatCurrency(productData.commission_with_currency)}</span></p>
                                        </div>
                                    </div>
                                    <div className="flex justify-center">
                                        <button
                                            className="px-8 py-1 bg-green-700 text-white rounded-md hover:bg-green-600 focus:outline-none muangay"
                                            onClick={handleClickBuyNow}
                                        >
                                            <FaCartShopping className="inline-block mr-2" /> Mua ngay
                                        </button>
                                    </div>
                                    <p className="mt-4 p-1 rounded-lg text-base text-center text-fuchsia-600 bg-fuchsia-50">Bấm "Mua ngay" để mở kênh TikTok của Tui3Gang. Rồi vào mục "Phần trưng bày" và tìm mua sản phẩm ở mục "Đề xuất" hoặc "Lựa chọn của nhà sáng tạo" là được hoàn tiền.</p>
                                </div>
                            <p className="text-sm text-center text-orange-500">*Lưu ý: Số tiền hoàn thực tế phụ thuộc vào giá trị đơn hàng.</p>
                        </div>
                    )}
                </>
                )}
                {!loading && !error && !productData && (
                    <>
                        <p className="mt-4 p-1 rounded-lg text-center text-fuchsia-600 bg-fuchsia-50">Nhập link sản phẩm TikTok để check hoàn tiền.</p>
                        <p className="mt-1 p-1 rounded-lg text-center">👉Xem hướng dẫn <Link to="/huong-dan" className='underline'>ở đây</Link>.</p>
                    </>
                )}
            </div>
            <div className="mt-5">
                <p className="mt-5 mb-2 p-1 rounded-lg text-center">Khoảng 1 giờ sau khi đặt hàng, thông tin đơn hoàn tiền sẽ cập nhật lên hệ thống. Nếu chưa thấy bạn có thể tìm đơn ở đây</p>
                <Link to="add" className="flex mx-auto justify-center px-8 py-2 bg-green-700 text-white rounded-md hover:bg-green-600 focus:outline-none">
                    <FaCirclePlus className="inline-block mt-1 mr-1" /> Tìm đơn TikTok
                </Link>
            </div>
            <div className="mb-3">
                <h2 className="font-bold pt-5 pb-2">Những lưu ý về hoàn tiền TikTok Shop</h2>
                <div>
                    <div className="mb-3 py-3 px-1 w-full bg-white relative border rounded-md drop-shadow-md">
                        <h3 className="font-bold pb-3">🎯Điều kiện để được ghi nhận hoàn tiền</h3>
                        <p className="mb-2 text-sm ml-2">✅Cần phải “Dán link” sản phẩm TikTok để kiểm tra sản phẩm có được hoàn tiền hay không. Nếu có, ấn vào “Mua ngay” để gắn tag hoàn tiền cho sản phẩm và mở ra trang TikTok của Tui3Gang.Com.</p>
                        <p className="mb-2 text-sm ml-2">✅Trên trang TikTok của Tui3Gang, vào “Phần trưng bày” và tìm sản phẩm cần mua ở mục "Đề xuất" hoặc "Lựa chọn của nhà sáng tạo" để cho vào giỏ hàng. Sau đó, tiến hành đặt hàng như bình thường là được ghi nhận hoàn tiền.</p>
                        <p className="mb-2 text-sm ml-2">✅Với mỗi 1 sản phẩm cần mua, bạn phải thao tác “Dán link” kiểm tra và ấn nút “Mua ngay” để gắn tag hoàn tiền. Sau đó, phải thêm sản phẩm vào giỏ hàng từ mục “Phần trưng bày”.</p>
                    </div>
                    <div className="mb-3 py-3 px-1 w-full bg-white relative border rounded-md drop-shadow-md">
                        <h3 className="font-medium pb-3">❌Trường hợp không được ghi nhận hoàn tiền</h3>
                        <p className="mb-2 text-sm ml-2">🚫Không thêm sản phẩm vào giỏ hàng từ “Phần trưng bày” của kênh Tui3Gang.Com.</p>
                        <p className="mb-2 text-sm ml-2">🚫Đơn hàng đổi trả hoặc hủy đơn từ phía bạn hoặc TikTok Shop.</p>
                        <p className="mb-2 text-sm ml-2">🚫Thêm sản phẩm vào giỏ hàng trước khi “Dán link” và ấn “Mua ngay” sẽ không được ghi nhận hoàn tiền.</p>
                        <p className="mb-2 text-sm ml-2">🚫Các trường hợp gian lận hoặc nghi ngờ gian lận được phát hiện bởi hệ thống của TikTok.</p>
                        <p className="mb-2 text-sm ml-2">🚫Một số sản phẩm, ngành hàng không được hoàn tiền theo chính sách của TikTok.</p>
                        <p className="mb-2 text-sm ml-2">🚫Mọi quyền quyết định cuối cùng về việc ghi nhận hoàn tiền thuộc về TikTokShop.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductCheckerAndAddToShowCase;
import React from 'react';
import { Link } from "react-router-dom";

const ShoppingCashback = () => {
  const items = [
    {
      to: "/shopee",
      imgSrc: '/images/cover-shopee.png',
      alt: 'Hoàn tiền Shopee',
      title: 'Hoàn tiền Shopee',
      cashback: 'Hoàn tiền 50%'
    },
    {
      to: "/lazada",
      imgSrc: '/images/cover-lazada.png',
      alt: 'Hoàn tiền Lazada',
      title: 'Hoàn tiền Lazada',
      cashback: 'Hoàn tiền 40%'
    },
    {
      to: "/tiktokshop",
      imgSrc: '/images/cover-tiktok.png',
      alt: 'Hoàn tiền Tiktok Shop',
      title: 'Hoàn tiền Tiktok',
      cashback: 'Hoàn tiền 50%'
    },
    {
      to: "/cashback-all",
      imgSrc: '/images/cover-all-shop.png',
      alt: 'Cashback online',
      title: 'Cashback online',
      cashback: 'Hoàn tiền 60%'
    },
  ];

  return (
    <div>
      <p className="text-center text-xl text-white my-5 p-2 rounded-lg bg-green-700">Mua sắm hoàn tiền trên sàn TMĐT</p>
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 gap-3 mb-5">
        {items.map((item, index) => (
          <Link
            key={index}
            to={item.to}
            className="flex flex-col items-center justify-center shadow-md rounded-lg"
          >
            <img
              src={item.imgSrc}
              alt={item.alt}
              className="w-full h-auto rounded-lg mb-2"
            />
            <span className="text-center text-xl">{item.title}</span>
            <span className="text-center pb-2 text-red-500">{item.cashback}</span>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ShoppingCashback;
import axios from 'axios';
import { BASE_URL } from '../configs';

// Hàm lấy danh sách popup đang hiển thị cho người dùng
export const fetchActivePopups = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/api/v1/other/popup-image`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Lỗi khi lấy danh sách popup');
    }
};
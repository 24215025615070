import React, { useState, useEffect } from 'react';
import { getTotalUsers } from '../../api/user/getTotalUserApi'; // API lấy tổng user
import { formattedDMY } from '../utils/formatTimeNow'

const HomeWelcome = () => {
    const [imageAnimation, setImageAnimation] = useState(false);
    const [text1Animation, setText1Animation] = useState(false);
    const [text2Animation, setText2Animation] = useState(false);
    const [paragraphAnimation, setParagraphAnimation] = useState(false);
    const [displayedUsers, setDisplayedUsers] = useState(0);
    const [totalUsers, setTotalUsers] = useState(0);
    const [steps, setSteps] = useState([]);

    useEffect(() => {
        setTimeout(() => {
            setText1Animation(true);
        }, 150); // Delay for text 1 animation

        setTimeout(() => {
            setText2Animation(true);
        }, 250); // Delay for text 2 animation

        setTimeout(() => {
            setImageAnimation(true);
        }, 60); // Delay for image animation

        setTimeout(() => {
          setParagraphAnimation(true);
        }, 300); // Delay for button animation
    }, []);

    // Hiển thị tổng số thành viên với hiệu ứng số tăng dần
    useEffect(() => {
      fetchTotalUsers();
    }, []);
  
    useEffect(() => {
      if (totalUsers > 0) {
        const stepValues = [0, 356, 596, 1289, 1395, 2543, 3559, 5236, 6095, 7501, 8911, 9213, 10130, 10598, totalUsers];
        setSteps(stepValues);
      }
    }, [totalUsers]);
  
    useEffect(() => {
      let index = 0;
      if (steps.length > 0) {
        const interval = setInterval(() => {
          setDisplayedUsers(steps[index]);
          index += 1;
          if (index >= steps.length) {
            clearInterval(interval);
          }
        }, 100); // Thời gian giữa các bước nhảy (100ms)
        return () => clearInterval(interval);
      }
    }, [steps]);
  
    const fetchTotalUsers = async () => {
      try {
        const data = await getTotalUsers();
        setTotalUsers(data.totalUsers + 11000);
      } catch (error) {
        console.error('Error fetching total users:', error);
      }
    };

    return (
        <div className="flex flex-col md:flex-row items-center justify-between mb-4">
            <style>
                {`
          .animate-fadein-up {
            transform: translateY(0);
            transition: transform 500ms, opacity 500ms;
            opacity: 1;
          }
          .text-hidden {
            transform: translateY(20px);
            opacity: 0;
          }
          .opacity-100 {
            opacity: 1;
            transition: opacity 1s;
          }
          .opacity-0 {
            opacity: 0;
          }
          .paragraph-start {
            transform: scale(0.5);
            opacity: 0;
          }
          .paragraph-end {
            transform: scale(1);
            transition: transform 0.5s ease;
            opacity: 1;
          }
        `}
            </style>
            <div className="w-full md:w-1/2 order-2 md:order-1 text-center md:text-left flex flex-col items-center md:items-start space-y-4">
                <p className={`text-3xl font-bold ${text1Animation ? 'animate-fadein-up' : 'text-hidden'}`}>
                    Hi! Tớ là <span className="text-green-700">Túi 3 Gang</span>
                </p>
                <p className={`text-xl ${text2Animation ? 'animate-fadein-up' : 'text-hidden'}`}>
                    Trang web chia sẻ mã giảm giá và <span className="font-medium text-green-700">hoàn tiền đến 60%</span> khi mua sắm trên Shopee, Lazada, TikTok và 50+ đối tác liên kết hoàn tiền khác.
                </p>
                <div className={`${paragraphAnimation ? 'paragraph-end' : 'paragraph-start'} flex flex-col justify-center border border-dashed border-green-700 rounded-md py-2 px-6`}>
                    <p className="text-xl text-center">
                      Đến hôm nay {formattedDMY}
                    </p>
                    <p className="font-bold text-3xl text-center text-green-700">{displayedUsers}</p>
                    <p className="text-xl text-center">
                      thành viên sử dụng
                    </p>
                </div>
            </div>
            <div className={`w-full md:w-1/2 order-1 md:order-2 md:mb-0 ${imageAnimation ? 'opacity-100' : 'opacity-0'}`}>
                <img
                    src="/images/imgslides/happy-online-shopping-with-tui3gang.png"
                    alt="Placeholder"
                    className="w-full h-auto"
                />
            </div>
        </div>
    );
};

export default HomeWelcome;
import axios from 'axios';
import { BASE_URL } from '../configs';

export const forgotPassword = async (email) => {
  try {
    const response = await axios.post(`${BASE_URL}/forgot-password`, { email });
    return response;
  } catch (error) {
    throw error;
  }
};
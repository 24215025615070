import React from 'react';

const BellNotifications = ({ notifications, onNotificationClick, onMarkAllAsRead }) => {
  // Sắp xếp thông báo mới nhất ở trên
  const sortedNotifications = [...notifications].sort((a, b) => new Date(b.createTime) - new Date(a.createTime));
  // Giới hạn chỉ hiển thị 5 thông báo
  const limitedNotifications = sortedNotifications.slice(0, 5);

  // Kiểm tra xem có thông báo chưa đọc hay không
  const hasUnreadNotifications = notifications.some((notification) => notification.unread);

  return (
    <div className="absolute right-0 top-full mt-2 bg-white border rounded shadow-lg w-80 z-50">
      <div>
        <h3 className="text-center font-medium my-3">Thông báo từ hệ thống</h3>
        {limitedNotifications.length > 0 ? (
          limitedNotifications.map((notification) => (
            <div
              key={notification.notificationId}
              className={`mb-2 pb-2 p-3 flex items-center cursor-pointer ${notification.unread ? 'font-bold' : ''} hover:bg-gray-50`}
              onClick={() => {
                onNotificationClick(notification.notificationId);
                // Mở link trong tab mới nếu link tồn tại
                if (notification.link) {
                  window.open(notification.link);
                }
              }}
            >
              <div>
                <p>{notification.title}</p>
                <p className="text-sm text-gray-600">{notification.message}</p>
              </div>
            </div>
          ))
        ) : (
          <p className="text-center text-gray-500 mb-4">Chưa có thông báo từ hệ thống</p>
        )}
        {/* Dòng "Đánh dấu tất cả là đã đọc" */}
        {hasUnreadNotifications && (
          <div className="text-center mt-3 py-2 bg-gray-50">
            <button
              className="text-gray-400 hover:underline"
              onClick={onMarkAllAsRead}
            >
              Đánh dấu tất cả là đã đọc
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default BellNotifications;
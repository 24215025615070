import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { verifyOneTimeCode } from '../../api/user/loginWithOTPApi';

export const verifyOneTimeCodeThunk = createAsyncThunk(
  'login/verifyOneTimeCode',
  async (code, thunkAPI) => {
    try {
      const response = await verifyOneTimeCode(code);
      Cookies.set('token', response.token, { expires: 365 }); // Lưu token vào cookies với thời hạn 365 ngày
      return response;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue({ message: 'Mã đăng nhập không đúng hoặc đã hết hạn.' });
      }
    }
  }
);

const oneTimeCodeSlice = createSlice({
  name: 'loginWithOTP',
  initialState: {
    user: null,
    token: Cookies.get('token') || null,
    isLoading: false,
    error: null,
    isLoggedIn: !!Cookies.get('token'), // Kiểm tra xem token có tồn tại trong cookies không
  },
  reducers: {
    setCredentials: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isLoggedIn = true;
    },
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.isLoggedIn = false;
      Cookies.remove('token');
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyOneTimeCodeThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(verifyOneTimeCodeThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.isLoggedIn = true;
      })
      .addCase(verifyOneTimeCodeThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { setCredentials, logout } = oneTimeCodeSlice.actions;

export default oneTimeCodeSlice.reducer;
import axios from 'axios';
import { BASE_URL } from '../configs';

export const getInfoProductShopeeApi = async ({ url, idUser }) => {
    try {
        const response = await axios.post(`${BASE_URL}/api/v1/shopee/get-info-product`, { url, idUser });
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
};
import axios from 'axios';
import { BASE_URL } from '../configs';

export const getProductsByShop = async (shopNameSlug, page = 1, limit = 10) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/v1/deal/shop/${shopNameSlug}`, {
      params: {
        page,
        limit
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching products by shop name:', error);
    throw error;
  }
};
import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { FaClipboard, FaCartShopping } from 'react-icons/fa6';
import { getInfoProductLink } from '../../api/shopcashbacks/getInfoProductTiktokShopApi';
import formatCurrency from '../utils/formatCurrency';

const FormCheckCashbackTikTokShop = () => {
    const [url, setUrl] = useState('');
    const user = useSelector((state) => state.profile.user);
    const idUser = user?.idUser || 'notlogin';
    const navigate = useNavigate();

    const inputRef = useRef(null);
    
    const [productData, setProductData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            if (url) {
                setLoading(true);
                setError('');
                try {
                    const data = await getInfoProductLink(url, idUser);
                    setProductData(data);
                } catch (err) {
                    setError(err.message);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchData();
    }, [url, idUser]);

    const handlePasteFromClipboard = () => {
        navigator.clipboard.readText().then((clipboardText) => {
            setUrl(clipboardText.trim());
        }).catch((error) => {
            console.error('Failed to read clipboard text:', error);
        });
    };
    
    const handleInputChange = (e) => {
        setUrl(e.target.value);
    };

    const handleInputFocus = () => {
        setUrl('');
    };

    const handleCustomPaste = (e) => {
        e.preventDefault();
        navigator.clipboard.readText().then((clipboardText) => {
            if (url.trim() === '') {
                setUrl(clipboardText.trim());
            }
        }).catch((error) => {
            console.error('Failed to read clipboard text:', error);
        });
    };

    const handleClickBuyNow = () => {
        if (idUser === 'notlogin') {
            navigate('/login'); // Chuyển hướng tới trang /login nếu chưa đăng nhập
        } else {
            const urlTiktok = 'https://www.tiktok.com/@tui3gangshop';
            window.open(urlTiktok, '_blank');
        }
    };

    const truncateText = (text, length) => {
        if (text.length <= length) return text;
        return text.substring(0, length) + '...';
    };

    return (
        <div>
            <div className="max-w mx-auto p-3 bg-white rounded-lg shadow-md">
                <div className="flex items-center border border-green-700 rounded-md px-3 py-1">
                    <input
                        type="text"
                        className="w-full focus:outline-none"
                        placeholder="Nhập link sản phẩm TikTok cần mua"
                        value={url}
                        onChange={handleInputChange}
                        ref={inputRef}
                        onFocus={handleInputFocus}
                        onPaste={handleCustomPaste}
                    />
                    <button
                        className="ml-2 focus:outline-none flex items-center border border-gray-200 p-2 rounded-lg"
                        onClick={handlePasteFromClipboard}
                    >
                        <FaClipboard className="text-gray-500 hover:text-gray-600 cursor-pointer pr-1" /> Dán
                    </button>
                </div>

                {loading && <p className="mt-4 text-center text-gray-600">Đang tải thông tin hoàn tiền...</p>}
                {error && <p className="mt-4 p-1 rounded-md text-center text-fuchsia-600 bg-fuchsia-50">Hãy nhập đúng link sản phẩm để được hoàn tiền!</p>}
                {productData && (
                    <>
                    {productData.message ? (
                        <p className="mt-4 p-1 rounded-lg text-center text-red-600 bg-red-50">{productData.message}</p>
                    ) : (
                        <div className="mt-4">
                            <p className="font-bold text-lg py-3">Thông tin hoàn tiền</p>
                                <div className="mb-2 p-2 bg-gray-50 rounded-md">
                                    <div className="flex items-center mb-3">
                                        <img
                                            src={productData.thumb_url_list}
                                            alt={productData.title}
                                            className="w-24 h-24 mr-4 rounded-md"
                                        />
                                        <div>
                                            <h2 className="text-md font-medium">{truncateText(productData.title, 50)}</h2>
                                            <p>Giá bán: <span className="px-1 py-[2px] rounded-lg text-fuchsia-600 bg-fuchsia-50">{productData.format_price}</span></p>
                                            <p>Hoàn tiền đến: <span className="text-lg px-2 py-[2px] rounded-lg text-green-700 bg-green-100">{formatCurrency(productData.commission_with_currency)}</span></p>
                                        </div>
                                    </div>
                                    <div className="flex justify-center">
                                        <button
                                            className="px-8 py-1 bg-green-700 text-white rounded-md hover:bg-green-600 focus:outline-none muangay"
                                            onClick={handleClickBuyNow}
                                        >
                                            <FaCartShopping className="inline-block mr-2" /> Mua ngay
                                        </button>
                                    </div>
                                    <p className="mt-4 p-1 rounded-lg text-base text-center text-fuchsia-600 bg-fuchsia-50">Bấm "Mua ngay" để mở kênh TikTok của Tui3Gang. Rồi vào mục "Phần trưng bày" và tìm mua sản phẩm ở mục "Đề xuất" hoặc "Lựa chọn của nhà sáng tạo" là được hoàn tiền.</p>
                                </div>
                            <p className="text-sm text-center text-orange-500">*Lưu ý: Số tiền hoàn thực tế phụ thuộc vào giá trị đơn hàng.</p>
                        </div>
                    )}
                </>
                )}
                {!loading && !error && !productData && (
                        <p className="mt-1 p-1 rounded-lg text-center">👉Xem hướng dẫn <Link to="/huong-dan" className='underline'>ở đây</Link>.</p>
                )}
            </div>
        </div>
    );
};

export default FormCheckCashbackTikTokShop;